import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPersonalDashboard, updateSubmissionData } from '../../../../../redux/slices/personalDashboardSlice';
import errorIcon from '../../../../../assets/icons/error-icon.svg'

function Forth() {
  const dispatch = useDispatch();
  const { updateSubmissionsData } = useSelector(selectPersonalDashboard);
  const [words, setWords] = useState('');

  const handleInput = (field, value) => {
    dispatch(updateSubmissionData({ field, value }));
  };

  return (
    <div className="flex flex-col md:w-[536px] md:h-[404px] pr-[16px] pb-[16px] gap-[24px]">
      <div className="flex flex-start text-left">
        <span className="text-primary text-left !text-[18px] !leading-[30px]">
          Tell us more detail about why you’re suggesting this service
        </span>
        {/*{
          !submDetailsValid &&
          (
            <span className="pt-9">
              <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
            </span>
          )
        }*/}
      </div>
      <div className="flex flex-col p-[2px] border-[1px] border-[#596073] rounded-[16px] md:w-[520px] md:h-[300px]">
        <textarea
          className="!h-[220px] placeholder:text-[#A5AEBC]  placeholder:!text-[18px] w-full resize-none border-none focus:ring-0 rounded-[12px] bg-[#363D50]"
          value={updateSubmissionsData?.submission_details || words}
          onChange={(e) => {
            setWords(e.target.value);
            handleInput('submission_details', e.target.value);
          }}
          placeholder={'Its features? How it’s better than an existing service you use? Anything!'}
        />
        <div className="flex flex-start pt-[40px] pl-[10px]">
          <span className="text-secondary text-left">{(updateSubmissionsData?.submission_details || words).length}/500</span>
        </div>
      </div>
    </div>
  );
}

export default Forth;

import { useState } from "react";
import BasicModal from "../../../components/models/models";
import FeedbackCard from "../../../components/card/feedbackerCards/feedbackCard";
import Header from "../../../components/Headers/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeedbacksRequest,
  selectPersonalDashboard,
} from "../../../redux/slices/personalDashboardSlice";
import { useEffect } from "react";

export const formatDate = (dateString) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month}, ${year}`;
};

function FeedbackModule({ show, setShow }) {
  const [id, setId] = useState({});
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const dispatch = useDispatch();
  const State = useSelector(selectPersonalDashboard);
  const { feedbacksData } = State;
  useEffect(() => {
    dispatch(getFeedbacksRequest());
  }, []);

  return (
    <div className="right_panel flex flex-col w-[100%] gap-[42px] pt-[20px] xl:pt-[32px] xl:max-w-[1099px] 6xl:max-w-[80%] pr-[24px]">
      <Header
        title={"Your Service Feedback"}
        subHeading={"Things you’ve mentioned about the services you use"}
        show={show}
        setShow={setShow}
        btns={false}
        line={true}
      />

      <div className="card-section">
        <div className="cards_wrap grid grid-cols-1 mdl:grid-cols-2 overflow-scroll scrollbar-hide gap-[40px] !h-[auto] max-h-[calc(100vh-212px)] sm:max-h-[calc(100vh-199px)] !pb-[20px] 2xl:px-0">
          {feedbacksData.feedbacks &&
            feedbacksData.feedbacks.map((feedback, index) => (
              <FeedbackCard
                state={state}
                setId={setId}
                popup={popup}
                setPopup={setPopup}
                setState={setState}
                key={index}
                feedbackId={feedback.id}
                userName={feedback.user_name}
                userId={feedback.user_id}
                serviceId={feedback.service_id}
                image={feedback.service.image_url}
                userFeedback={feedback.feedback_details}
                name={feedback.service.name}
                subName={feedback.service.service_type}
                rating={feedback.rating}
                date={formatDate(feedback.updated_at)}
              />
            ))}
          {!feedbacksData.feedbacks && [1,2,3,4,5].map((el, index)=> (
            <FeedbackCard key={index} />
          ))}
        </div>
      </div>

      {setPopup && (
        <BasicModal
          feedid={id}
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
        />
      )}
    </div>
  );
}

export default FeedbackModule;

import { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "../../../components/Buttons/button";
import Card from "../../../components/card/feedbackerCards/card";
import BasicModal from "../../../components/models/models";
import Header from "../../../components/Headers/Header";
import savingIcon from "../../../assets/icons/fluent_savings.svg";
import chevronIcon from "../../../assets/icons/chevron_down.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {useFormattedAmount} from "../../../hooks/useFormattedAmount";
import { savingsOpportunities } from "../../../services/servicesData";
import SavingOpportunity from '../../../components/opportunity/SavingOpportunity';
import { selectUser } from "../../../redux/slices/userSlice";
import {
  getServicesRequest,
  selectPersonalDashboard,
  getSavingOpportunity
} from "../../../redux/slices/personalDashboardSlice";
import {
  selectCompanyDashboard
} from "../../../redux/slices/companyDashboardSlice";

function ServicesModule({ show, setShow }) {

  const dispatch = useDispatch();
  const formatFunction = useFormattedAmount();
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [billingPeriod, setBillingPeriod] = useState();

  const UserState = useSelector(selectUser);
  const {userDetails} = UserState
  const State = useSelector(selectPersonalDashboard);
  const { servicesData, savingOpportunityData } = State;
  const {opportunityStatusId} = useSelector(selectCompanyDashboard);

  useEffect(() => {
    dispatch(getServicesRequest());
  }, [dispatch]);

  useEffect(() => {
    setBillingPeriod(servicesData?.billing_period);
  }, [servicesData]);

  useEffect(()=>{
    if(userDetails?.user){
      dispatch(getSavingOpportunity(userDetails?.user?.id,"emp_id"))
    }
  },[userDetails?.user, opportunityStatusId])

  return (
    <div className="right_panel flex flex-col gap-8 px-[0] pt-[20px] xl:max-w-[1099px] 6xl:max-w-[80%] h-screen pr-[24px]">
      <Header
        title={"Your Services"}
        subHeading={`Billing Period: ${billingPeriod?.month}, ${billingPeriod?.year}`}
        setShow={setShow}
        btns={true}
      />

      { savingOpportunityData?.savings_opportunities?.length > 0 && <SavingOpportunity opportunity={savingOpportunityData} userId={userDetails?.user?.id} companyName={userDetails?.user?.company_name} /> }

      <div className="card-section">
        <div className={`cards_wrap inner_space flex flex-wrap gap-[32px] justify-start pb-[20px] overflow-y-scroll scrollbar-hide ${savingOpportunityData?.savings_opportunities?.length > 0? '!h-[calc(100vh-259px)]':'!h-[calc(100vh-125px)]'}`}>
          {servicesData?.services &&
            servicesData.services.map((service, index) => (
              <Card
                state={state}
                popup={popup}
                setPopup={setPopup}
                setState={setState}
                modalId={service.id}
                servicesData={servicesData}
                key={index}
                image={service.image_url}
                title={service.name}
                price={formatFunction(service.cost_per_month)}
                rating={service.rating}
                btns={true}
                navLink={`/personal_dashboard/services/usage_report/${service.id}`}
              />
            ))}
        </div>
      </div>

      {setPopup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
        />
      )}
    </div>
  );
}

export default ServicesModule;

import React from 'react'
import First from './tabs/first';
import Second from './tabs/second';
// import Third from './tabs/third';
import Forth from './tabs/forth';

function Tabs({Tab, formValid, nameValid, categoryValid, websiteUrlValid}) {

  return (
    <div>
      {Tab === 1 && <First formValid={formValid} nameValid={nameValid} categoryValid={categoryValid} websiteUrlValid={websiteUrlValid}/>}
      {Tab === 2 && <Second/>}
      {/*{Tab === 3 && <Third/>}*/}
      {Tab === 3 && <Forth />}
    </div>
  )
}

export default Tabs
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Buttons/button";

export default function ConfirmModel({ setstate, setPopup, id,  setConfirmDelete, setConfirmIgnore }) {
  const HandleButton = () => {
    if (id){
      setConfirmDelete(true)
    }
    setConfirmIgnore(true)
  }

  return (
    <div>
      <div className="transform rounded-[24px] bg-[#363D50] transition-all md:w-[365px] md:h-[200px] flex">
        <div className="flex flex-col px-[24px] py-[24px]">
          <div className="titleBar pb-6">Are you sure !</div>

          <div className=" flex flex-col gap-[32px]">
            <div className="flex flex-col md:flex-row justify-center items-center gap-[16px]">
              <Button
                onClick={() => {
                  setPopup(false);
                }}
                className={"!w-[150px] btnHover"}
                position={"center"}
                outlined={true}
                secondary={true}
              >
                NO
              </Button>
              <Button
                onClick={HandleButton}
                className={"!w-[150px] btnHover"}
                fill={true}
                position={"center"}
              >
                YES
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useMemo } from "react";
import Image from "../../../assets/images/model_Image.svg";
import Button from "../../Buttons/button";
import { history } from "../../../services/historyData";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getHistoryRequest,
  selectPersonalDashboard,
} from "../../../redux/slices/personalDashboardSlice";
import { selectCompanyDashboard } from "../../../redux/slices/companyDashboardSlice";
import { selectUser } from "../../../redux/slices/userSlice";
import { useLocation } from "react-router-dom";
import {useFormattedAmount} from "../../../hooks/useFormattedAmount";
import { getInitials } from '../../../shared/lib/getInitials';
import { getRandomHex } from '../../../shared/lib/getRandomHex';

function HistoryModel({ setstate, setPopup }) {
  const [btn1, setbtn1] = useState(false);
  const [btn2, setbtn2] = useState(false);
  const formatFunction = useFormattedAmount();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearData, setYearData] = useState({});
  const PersonalState = useSelector(selectPersonalDashboard);
  const CompanyState = useSelector(selectCompanyDashboard);
  const { userDetails } = useSelector(selectUser);
  const { pathname } = useLocation();

  const { historyData } = PersonalState;
  const { CompanyhistoryData } = CompanyState;

  const incrementYear = () => {
    if (currentYear < new Date().getFullYear()) {
      setCurrentYear(currentYear + 1);
    }
  };

  const decrementYear = () => {
    setCurrentYear(currentYear - 1);
  };

  const dataToUse = pathname?.includes("personal_dashboard")
    ? historyData
    : CompanyhistoryData;
  const NameToUse = pathname?.includes("personal_dashboard")
    ? userDetails?.user?.name
    : CompanyhistoryData?.company_name;
  const ImageUrlToUse = pathname?.includes("personal_dashboard")
    ? userDetails?.user?.image_url
    : userDetails?.user?.company_image_url;

  useEffect(() => {
    if (dataToUse && dataToUse[currentYear]) {
      setYearData(dataToUse[currentYear]);
    } else {
      setYearData(null);
    }
  }, [dataToUse, historyData, CompanyhistoryData, currentYear]);

  const [hexColor, hexWithOpacity] = useMemo(
    () => getRandomHex(),
    []
  );

  return (
    <div className="flex flex-col w-[320px] h-[585px] md:w-[500px] rounded-[24px] bg-[#363D50]">
      <div className="md:w-[500px] px-[24px] flex flex-col pt-[24px] gap-[16px]">
        <div className="flex justify-between">
          <div className="flex flex-start items-center gap-[16px]">
            {
              pathname?.includes("personal_dashboard")?<div
              style={{
                backgroundColor: hexWithOpacity,
                color: hexColor,
              }}
              className="w-[40px] h-[40px] rounded-full flex items-center justify-center !text-[14px] !font-semibold"
            >
              {getInitials(NameToUse)}
            </div>:<img
              className="w-[40px] h-[40px] rounded-lg"
              src={ImageUrlToUse}
              alt="service-img"
            />
            }            
            <div className="flex flex-col">
              <span className="text-primary !text-left">Cost History</span>
              <span className="text-secondary !text-left">{NameToUse}</span>
            </div>
          </div>
          <div className="flex h-[40px] justify-end items-center">
            <svg
              className="cursor-pointer"
              onClick={() => {
                setPopup(false);
              }}
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div className="flex flex-col gap-[16px]">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-primary !text-[24px]">{currentYear}</span>
            </div>
            <div className="flex gap-[5px]">
              <Button
                onMouseEnter={() => {
                  !btn1 && setbtn1(!btn1);
                }}
                onClick={decrementYear}
                onMouseLeave={() => {
                  btn1 && setbtn1(!btn1);
                }}
                secondaryHoverOutlined={true}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.3852 3.57854C13.7758 3.96906 13.7758 4.60223 13.3852 4.99275L8.55664 9.82136L13.3852 14.65C13.7758 15.0405 13.7758 15.6737 13.3852 16.0642C12.9947 16.4547 12.3616 16.4547 11.971 16.0642L7.14243 11.2356C6.36138 10.4545 6.36138 9.18819 7.14243 8.40714L11.971 3.57854C12.3616 3.18801 12.9947 3.18801 13.3852 3.57854Z"
                    fill={btn1 ? "#FFD74A" : "white"}
                  />
                </svg>
              </Button>
              <Button
                onMouseEnter={() => {
                  !btn2 && setbtn2(!btn2);
                }}
                onClick={incrementYear}
                onMouseLeave={() => {
                  btn2 && setbtn2(!btn2);
                }}
                secondaryHoverOutlined={true}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.61475 3.57854C6.22423 3.96906 6.22423 4.60223 6.61475 4.99275L11.4434 9.82136L6.61475 14.65C6.22423 15.0405 6.22423 15.6737 6.61475 16.0642C7.00528 16.4547 7.63844 16.4547 8.02897 16.0642L12.8576 11.2356C13.6386 10.4545 13.6386 9.18819 12.8576 8.40714L8.02897 3.57854C7.63844 3.18801 7.00528 3.18801 6.61475 3.57854Z"
                    fill={btn2 ? "#FFD74A" : "white"}
                  />
                </svg>
              </Button>
            </div>
          </div>

          <div className="bg-[#41485C] w-[100%] h-[3px]" />
        </div>

        <div className="flex flex-col h-[400px] px-[5px] overflow-y-scroll scrollbar-hide">
          {yearData === null ? (
            <div className="text-secondary">
              No data available for the this year
            </div>
          ) : (
            Object.entries(yearData).map(([month, data], index) => (
              <div key={index}>
                <div className="flex flex-col md:flex-row justify-between items-center py-[16px] gap-[16px]">
                  <div>
                    <span className="text-primary !font-semibold">{month}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-primary !font-semibold">
                      {formatFunction(data.cost)}
                    </span>
                    <span className="text-secondary">
                      {data.services_count} services
                    </span>
                  </div>
                </div>
                <div className="bg-[#41485C] w-[100%] h-[2px]" />
              </div>
            ))
          )}
          {/* {history.map((item, index) => (
            <div>
              <div
                key={index}
                className="flex flex-col md:flex-row justify-between items-center py-[16px] gap-[16px]"
              >
                <div>
                  <span className="text-primary !font-semibold">{item.month}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-primary !font-semibold">{item.amount}</span>
                  <span className="text-secondary">
                    {item.services} services
                  </span>
                </div>
              </div>
              <div className="bg-[#41485C] w-[100%] h-[2px]" />
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}

export default HistoryModel;

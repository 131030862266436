import { useEffect, useState } from "react";
import Layout from "./components/layout/layout";
import ServicesModule from "./modules/feedbacker/services-module/servicesModule";
import ExpensesModule from "./modules/feedbacker/expenses-module/expensesModule";
// import "./styles/global.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SubmissionModule from "./modules/feedbacker/submission-module/submissionModule";
import RequestModule from "./modules/feedbacker/requests-module/requestsModule";
import TeamsModule from "./modules/feedbacker/teams-module/teamsModule";
import FeedbackModule from "./modules/feedbacker/feedback-module/feedbackModule";
import UsageReportModule from "./modules/feedbacker/usage-report/usageReportModule";
import TeamServicesModule from  "./modules/feedbacker/teams-module/teamsServicesModule";
import TeamServiceDetails from './modules/feedbacker/teams-module/service-details/team-service-detail-module'
import UsageCompanyReportModule from "./modules/evaluator/company-dashboard/usage-report/UsageCompanyReportModule";

import EmployeeUsageReportModule from "./modules/evaluator/employee-report/employee-report-module";
import HomeModule from "./modules/evaluator/company-dashboard/home/home-module";
import CompanyServicesModule from './modules/evaluator/company-dashboard/services/services-module'
import CompanyExpensesModule from './modules/evaluator/company-dashboard/expenses/expensesModule'
import CompanyEmployeesModule from './modules/evaluator/company-dashboard/services/employees-module'
import CompanySubmissionModule from './modules/evaluator/company-dashboard/services/submission-module'
import ServiceDetails from './modules/evaluator/company-dashboard/service-details/service-detail-module'
import TaskerHomeModule from './modules/Tasker/Home/taskerHomeModule'
import Login from "./modules/auth/login-module";
import SignUp from "./modules/auth/signUp-module";
import {useSelector} from 'react-redux'
import { selectUser } from "./redux/slices/userSlice";
import PasswordReset from "./modules/auth/password-reset-module";
import NewPassword from "./modules/auth/new-password-module";
const isAuthenticated = () => {
  const token = localStorage.getItem('accessToken');
  return token !== '' && token !== null;
}

function App() {
  const [show, setShow] = useState(false);
  const { profile } = useSelector(selectUser);

  useEffect(() => {
    const tokenExists = isAuthenticated();
    if (tokenExists) {
      setShow(true);
    }
  }, [profile]);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={isAuthenticated() ? <Navigate to="/personal_dashboard/services" replace /> : <Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route
          path="/"
          element={isAuthenticated() ? <Navigate to="/personal_dashboard/services" replace /> : <Navigate to="/login" replace />}
        />
        <Route path="/*" element={isAuthenticated() ? (
          <Layout show={show} setShow={setShow}>
            <Routes>
          <Route
            path="/personal_dashboard"
            element={<Navigate to="/personal_dashboard/services" replace />}
          />
          <Route path="personal_dashboard">
            <Route path="services" >
              <Route index element={<ServicesModule show={show} setShow={setShow} />}/>
              <Route exact path="usage_report/:id" element={<UsageReportModule show={show} setShow={setShow} path={['Services', 'Usage Report']} />} />
              <Route exact path="teams/employee_report/:id" element={<UsageCompanyReportModule show={show} setShow={setShow} path={['Personal Dashboard', 'Teams', 'Usage Report']} />} />
            </Route>
            <Route path="expenses" >
              <Route index element={<ExpensesModule show={show} setShow={setShow} />}/>              
            </Route>
            <Route path="team_services" >
              <Route index element={<TeamServicesModule show={show} setShow={setShow} path={['Company Dashboard', 'Services, Employees']} />} />
              <Route exact path="usage_report/:id" element={<TeamServiceDetails show={show} setShow={setShow} path={['Services', 'Usage Report']} />} />
              <Route exact path="teams/employee_report/:id" element={<UsageCompanyReportModule show={show} setShow={setShow} path={['Teams', 'Usage Report']} />} />
            </Route>
            <Route exact path="submissions" element={<SubmissionModule show={show} setShow={setShow} />}></Route>
            <Route exact path="requests" element={<RequestModule show={show} setShow={setShow} />}></Route>
            <Route exact path="feedbacks" element={<FeedbackModule show={show} setShow={setShow} />}></Route>
            <Route path="teams/:user_id">
              <Route index element={<TeamsModule show={show} setShow={setShow} />} />
              <Route exact path="employee_report" element={<EmployeeUsageReportModule show={show} setShow={setShow} path={['Teams', 'Employee Report']} />} />              
            </Route>
            <Route path="teams">
              <Route exact path="employee/usage_report/:id" element={<UsageCompanyReportModule show={show} setShow={setShow} path={['Teams', 'Employee Report', 'Usage Report']} />} />
            </Route>
          </Route>
          <Route
            path="/company_dashboard"
            element={<Navigate to="/company_dashboard/home" replace />}
          />
          <Route path="company_dashboard">
            <Route path="home" >
              <Route index element={<HomeModule show={show} setShow={setShow} />} />
              <Route exact path="service_report/:id" element={<ServiceDetails show={show} setShow={setShow} path={['Home', 'Service Report']} />} />
              <Route exact path="service/usage_report/:id" element={<UsageCompanyReportModule show={show} setShow={setShow} path={['Home', 'Service Report', 'Usage Report']} />} />
              <Route exact path="employee_report/:id" element={<EmployeeUsageReportModule  show={show} setShow={setShow} path={['Home', 'Employee Report']} />} />
              <Route exact path="employee/usage_report/:id" element={<UsageCompanyReportModule show={show} setShow={setShow} path={['Home', 'Employee Report', 'Usage Report']} />} />
            </Route>
            <Route index path="home"element={<HomeModule show={show} setShow={setShow} />} />
            <Route path="services" >
              <Route index element={<CompanyServicesModule show={show} setShow={setShow} path={['Company Dashboard', 'Services, Employees']} />} />
              <Route exact path="service_report/:id" element={<ServiceDetails show={show} setShow={setShow} path={['Services', 'Service Report']} />} />
              <Route exact path="usage_report/:id" element={<UsageCompanyReportModule show={show} setShow={setShow} path={['Services', 'Service Report', 'Usage Report']} />} />
            </Route>
            <Route path="expenses" >
              <Route index element={<CompanyExpensesModule show={show} setShow={setShow} />}/>              
            </Route>
            <Route path="employees" >
              <Route index element={<CompanyEmployeesModule show={show} setShow={setShow} path={['Company Dashboard','Employees']} />} />
              <Route exact path="employee_report/:id" element={<EmployeeUsageReportModule  show={show} setShow={setShow} path={['Employees', 'Employee Report']} />} />
              <Route exact path="service/employee_report/:id" element={<UsageCompanyReportModule show={show} setShow={setShow} path={['Employees', 'Employee Report', 'Usage Report']} />} />
            </Route>
            <Route path="employees/:user_id" >
              <Route index element={<CompanyEmployeesModule show={show} setShow={setShow} path={['Company Dashboard','Employees']} />} />
            </Route>
            <Route path="service_submissions" >
              <Route index element={<CompanySubmissionModule show={show} setShow={setShow} path={['Company Dashboard','Service Submission']} />} />
              <Route exact path="service_details" element={<ServiceDetails show={show} setShow={setShow} path={['Company Dashboard', 'Services', 'Service Details']} />} />
            </Route>
          </Route>
          <Route
            path="/tasker"
            element={<Navigate to="/tasker/home" replace />}
          />
          <Route path="tasker">
            <Route index path="home"element={<TaskerHomeModule show={show} setShow={setShow} path={['Tasker', 'Home']} />} />
            <Route path="services" >
              <Route index element={<CompanyServicesModule show={show} setShow={setShow} path={['Company Dashboard', 'Services']} />} />
              <Route exact path="service_details" element={<ServiceDetails show={show} setShow={setShow} path={['Company Dashboard', 'Services', 'Service Details']} />} />
            </Route>
          </Route>
          <Route
            path="/"
            element={<Navigate to="/personal_dashboard/services" replace />}
          />

        </Routes>
          </Layout>
        ) : <Navigate to="/login" replace />}
        />
      </Routes>
    </Router>
  );
}

export default App;

import React from "react";

const ManageAlert = ({close, setClose}) => {

  return (
  	<>
  	 	{ !close && 
	    	<div className="flex items-center gap-[16px]">
		      <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
		       	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect width="32" height="32" rx="8" fill="#242B3E"/>
							<path d="M16 25C17.78 25 19.5201 24.4722 21.0001 23.4832C22.4802 22.4943 23.6337 21.0887 24.3149 19.4442C24.9961 17.7996 25.1743 15.99 24.8271 14.2442C24.4798 12.4984 23.6226 10.8947 22.364 9.63604C21.1053 8.37737 19.5016 7.5202 17.7558 7.17294C16.01 6.82567 14.2004 7.0039 12.5558 7.68509C10.9113 8.36628 9.50571 9.51983 8.51677 10.9999C7.52784 12.4799 7 14.22 7 16C7 17.44 7.338 18.8 7.94 20.007C8.393 20.918 7.763 22.147 7.523 23.044C7.46983 23.2424 7.46982 23.4513 7.52297 23.6497C7.57613 23.8481 7.68057 24.029 7.8258 24.1742C7.97103 24.3194 8.15194 24.4239 8.35033 24.477C8.54872 24.5302 8.75761 24.5302 8.956 24.477C9.853 24.237 11.082 23.607 11.993 24.061C13.2382 24.6793 14.6097 25.0007 16 25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M18.9529 15.1299H17.1863L17.1321 15.5169H18.8988L18.7704 16.4199H17.0037L16.5309 19.1631C16.4929 19.3992 16.6954 19.5005 16.9844 19.5005C17.3069 19.5005 17.6971 19.3592 18.2918 19.0386L17.959 21.0969C17.3301 21.4174 16.7386 21.5 16.1955 21.5C14.1915 21.5 13.3846 20.5667 13.68 18.7277L14.0818 16.4193H12.25L12.3784 15.5162H14.2102L14.2644 15.1292H12.4371L12.7486 13.0652H14.5759L14.9765 10.5L17.8977 10.5393L17.4972 13.0659H19.2638L18.9529 15.1299Z" fill="white"/>
						</svg>
		      </div>

		      <div className="flex flex-start gap-[2px]">
		        <div className="text-secondary !text-[14px] !leading-[20px] text-left">
		          Thryft uses service’s user usage data to more accurately report financial trends and opportunities. Upload user usage data monthly for more accurate report generation
		        </div>
		        <div>
		          <button onClick={()=> setClose(true)}>
			        	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z" fill="white"/>
								</svg>
							</button>
		        </div>
		      </div>
	    	</div>
	  	}
	  </>
  )
}

export default ManageAlert
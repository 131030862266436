import React from "react";
import Button from "../../../../../components/Buttons/button";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getUsageFeedbackData } from "../../../../../redux/slices/companyDashboardSlice";
import negativeHoverIcon from "../../../../../assets/images/nagative_hover.png";
import neutralHoverIcon from "../../../../../assets/images/neutral_hover.png";
import smileyHoverIcon from "../../../../../assets/images/smiley_hover.png";

function NegativeFeedbackCard({
  setPopup,
  setState,
  feedback,
  setFeedbackID,
  id,
  serviceId,
  setDashboard,
}) {
  const dispatch = useDispatch();
  return (
    <div className="grid grid-cols-1 gap-[48px] justify-center">
      <div className="flex flex-col bg-[#363D50] rounded-[32px] pb-[16px]  max-w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.66634 9.33333C4.66634 8.22876 5.56177 7.33333 6.66634 7.33333H25.333C26.4376 7.33333 27.333 8.22876 27.333 9.33333V20C27.333 21.1046 26.4376 22 25.333 22H19.7709C18.8869 22 18.039 22.3512 17.4139 22.9763L12.1949 28.1953C11.9346 28.4556 11.9346 28.8777 12.1949 29.1381C12.4553 29.3984 12.8774 29.3984 13.1377 29.1381L18.3567 23.9191C18.7318 23.544 19.2405 23.3333 19.7709 23.3333H25.333C27.174 23.3333 28.6663 21.8409 28.6663 20V9.33333C28.6663 7.49238 27.174 6 25.333 6H6.66634C4.82539 6 3.33301 7.49238 3.33301 9.33333V20C3.33301 21.8409 4.82539 23.3333 6.66634 23.3333H11.333C11.7012 23.3333 11.9997 23.0349 11.9997 22.6667C11.9997 22.2985 11.7012 22 11.333 22H6.66634C5.56177 22 4.66634 21.1046 4.66634 20V9.33333ZM10.6663 14C10.2982 14 9.99967 14.2985 9.99967 14.6667C9.99967 15.0349 10.2982 15.3333 10.6663 15.3333H10.6797C11.0479 15.3333 11.3463 15.0349 11.3463 14.6667C11.3463 14.2985 11.0479 14 10.6797 14H10.6663ZM15.9997 14C15.6315 14 15.333 14.2985 15.333 14.6667C15.333 15.0349 15.6315 15.3333 15.9997 15.3333H16.013C16.3812 15.3333 16.6797 15.0349 16.6797 14.6667C16.6797 14.2985 16.3812 14 16.013 14H15.9997ZM21.333 14C20.9648 14 20.6663 14.2985 20.6663 14.6667C20.6663 15.0349 20.9648 15.3333 21.333 15.3333H21.3463C21.7145 15.3333 22.013 15.0349 22.013 14.6667C22.013 14.2985 21.7145 14 21.3463 14H21.333Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Negative Feedback
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Feedback that can lead to oppertunity
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[360px] overflow-y-scroll scrollbar-hide px-[16px]">
          <div className="flex flex-col px-[8px] py-[3px]">
            {feedback?.map((item) => (
              <div
                onClick={() => {
                  setPopup(true);
                  setState(13);
                  setFeedbackID(item.id);
                  setDashboard("companyDahboardfeedback");
                }}
                key={item.id}
                className="flex flex-col card-animate border-t-[1px] border-[#41485C] hover:rounded-[8px] cursor-pointer"
              >
                {/* <div className='h-[1px] bg-[#41485C] w-[100%]' /> */}
                <div className="flex flex-col py-[10px] px-[3px] gap-[8px] justify-center">
                  <div className="flex flex-row justify-between gap-[4px]">
                    <div>
                      {item.feedback_rating === "positive" && (
                        <img
                          src={smileyHoverIcon}
                          alt="rating-img"
                          className="w-[24px] h-[24px] rating_icon active"
                        />
                      )}

                      {item.feedback_rating === "general" && (
                        <img
                          src={neutralHoverIcon}
                          alt="rating-img"
                          className="w-[24px] h-[24px] rating_icon active"
                        />
                      )}

                      {item.feedback_rating === "negative" && (
                        <img
                          src={negativeHoverIcon}
                          alt="rating-img"
                          className="w-[24px] h-[24px] rating_icon active"
                        />
                      )}
                    </div>
                    <div className="flex flex-row gap-[8px] items-center">
                      <div className="flex flex-row gap-[8px] items-center">
                        <span className="text-secondary">
                          {item?.feedback_date}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className="text-secondary !leading-[20px]">
                    {item?.feedback_details}
                  </span>
                </div>
              </div>
            ))}
            {!feedback && (
              <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
                <Skeleton count={3} />
              </SkeletonTheme>
            )}
          </div>
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Button
            onClick={() => {
              setPopup(true);
              setState(21);
              dispatch(getUsageFeedbackData(id));
            }}
            secondary={true}
            hoverColor={true}
          >
            View all
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NegativeFeedbackCard;

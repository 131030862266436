import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Button from "../../../../components/Buttons/button";
import {useFormattedAmount} from "../../../../hooks/useFormattedAmount";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

function PillCards({
  setPopup,
  setState,
  userId,
  totalTeamCost,
  averageEmployeeCost,
  totalSubmissions,
  totalFeedbacks,
  totalRequests,
  setDashboard,
}) {
  const formatFunction = useFormattedAmount();
  return (
    <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
      <div className="gap-[32px]">
        <div className="flex flex-wrap gap-[32px]">
          <div className="flex gap-[12px] w-full max-w-[100%] h-[100px] sm:max-w-[calc(50%-16px)] p-[24px] rounded-[32px] bg-[#363D50]">
            <div className="flex justify-center flex-col gap-[6px] w-[203px] h-[51px]">
              {totalTeamCost && (
                <span className="text-primary !text-[18px]">{formatFunction(totalTeamCost)}</span>
              )}
              {totalTeamCost === undefined && <Skeleton className="!w-[132px]" />}
              <span className="text-secondary !leading-[21px]">
                {" "}
                Total team cost
              </span>
            </div>
          </div>

          <div className="flex justify-center flex-col w-full max-w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] sm:max-w-[calc(50%-16px)] rounded-[32px] bg-[#363D50]">
            {averageEmployeeCost && (
              <span className="text-primary !text-[18px]">{formatFunction(averageEmployeeCost)}</span>
            )}
            {averageEmployeeCost === undefined && <Skeleton className="!w-[132px]" />}
            <span className="text-secondary !leading-[21px]">
              {" "}
              Average employee cost
            </span>
          </div>
        </div>
        <div className="flex pt-[42px] gap-[32px]">
          <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
            <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
              {totalRequests && (
                <span className="text-primary !text-[18px]">
                  {totalRequests.length}
                </span>
              )}
              {!totalRequests && <Skeleton className="!w-[132px]" />}
              <span className="text-secondary !leading-[21px]">Requests</span>
            </div>
            <Button
              className={cn({
                invisible: !totalRequests.length,
              })}
              onClick={() => {
                setPopup(true);
                setState(15);
                setDashboard("personalDashboard");
              }}
              secondary
              hoverColor
            >
              View
            </Button>
          </div>

          <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
            <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
              {totalFeedbacks && (
                <span className="text-primary !text-[18px]">
                  {totalFeedbacks.length}
                </span>
              )}
              {!totalFeedbacks && <Skeleton className="!w-[132px]" />}
              <span className="text-secondary !leading-[21px]">Feedback</span>
            </div>
            <Button
              className={cn({
                invisible: !totalFeedbacks.length,
              })}
              onClick={() => {
                setPopup(true);
                setState(16);
                setDashboard("personalDashboard");
              }}
              secondary
              hoverColor
            >
              View
            </Button>
          </div>

          <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
            <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
              {totalSubmissions && (
                <span className="text-primary !text-[18px]">
                  {totalFeedbacks.length}
                </span>
              )}
              {!totalSubmissions && <Skeleton className="!w-[132px]" />}
              <span className="text-secondary !leading-[21px]">
                Submissions
              </span>
            </div>
            <Button
              className={cn({
                invisible: !totalSubmissions.length,
              })}
              onClick={() => {
                setPopup(true);
                setState(17);
                setDashboard("personalDashboard");
              }}
              secondary
              hoverColor
            >
              View
            </Button>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default PillCards;

import React, { useState, useEffect, useRef } from "react";
import StatusBar from "../../../components/statusBar/StatusBar";
import Button from "../../../components/Buttons/button";
import menuDot from "../../../assets/images/menuDot.png";
import {useDispatch, useSelector} from "react-redux"
import {
	notifyMultipleServiceOwner,
	notifySingleServiceOwner,
  selectCompanyDashboard,
  getManageServicesDataScore,
  setUserServiceFalse
} from "../../../redux/slices/companyDashboardSlice";
import { useNavigate } from 'react-router-dom';
import BasicModal from "../../models/models";


function ManageDataScope({ setstate, setModel, setPopup }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dropdownRefs = useRef([]);
	const [popUP, setPopUP] = useState(false);
  const [state, setState] = useState(0);
	const [checkedItems, setCheckedItems] = useState({});
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [serviceId, setServiceId] = useState([]);
  const [id, setId] = useState("")

	const State = useSelector(selectCompanyDashboard);
  const { manageServicesData, UserServiceData } = State;

  const percentage = manageServicesData?.data_score_percentage
  const services = manageServicesData?.services
  const totalUserServiceDetails = manageServicesData?.total_contracts
  const completeUserServiceDetails = manageServicesData?.services_with_user_details_count
  const reportDay = manageServicesData?.monthly_report_day
  const upcomingReportMonth = manageServicesData?.upcoming_report_month

  const [ managePopup , setMangePopup] = useState(false);
  const [manageState, setManageState] = useState(0);

  useEffect(()=>{
    dispatch(getManageServicesDataScore())
    dispatch(setUserServiceFalse());
  },[dispatch, UserServiceData]);

  const handleMasterCheckbox = (e) => {
  	const checked = e.target.checked;

    const updatedCheckedItems = {};
    services.forEach((service) => {
      updatedCheckedItems[service.id] = checked;
    });

    setCheckedItems(updatedCheckedItems);
    setIsMasterChecked(checked);
  }

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;

    setCheckedItems((prevItems) => ({
      ...prevItems,
      [id]: checked,
    }));

    if (checked === false) {
      setIsMasterChecked(false);
    }
  };

   useEffect(() => {
    const selectedServiceIds = Object.entries(checkedItems)
      .filter(([id, isChecked]) => isChecked)
      .map(([id]) => parseInt(id));

    setServiceId(selectedServiceIds);
  }, [checkedItems]);

	useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click was outside of any dropdown
      if (dropdownRefs.current.every(ref => ref && !ref.contains(event.target))) {
        setDropdownOpen(null);  // Close dropdown if the click is outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRefs]);

  const toggleDropdown = (index) => {
    setDropdownOpen((prevOpenDropdown) => (prevOpenDropdown === index ? null : index));
  };

  const isAnyCheckboxChecked = Object.values(checkedItems).some((isChecked) => isChecked);

  const handleMultipleServiceOwner = () => {
		dispatch(notifyMultipleServiceOwner(serviceId))
  }

  const handleSingleServiceOwner = (serviceId) => {
		dispatch(notifySingleServiceOwner(serviceId))
  }

  const handleViewServiceReport = (service_id) => {
    navigate(`/company_dashboard/services/service_report/${service_id}`);
    setPopup(false)
    setModel(false)
  }

	const handleManageService = (service_id) => {
		setManageState(35)
		setMangePopup(true)
		setId(service_id)
	}

	const handleUploadServiceUsageData = (service_id) => {
		setManageState(43)
		setMangePopup(true)
		setId(service_id)
	}

	const formatDate = (prevMonth) => {
	  const date = new Date(prevMonth);
  	date.setMonth(date.getMonth() - 1);
  	return date.toLocaleDateString('en-US', { month: 'long' });
	};


  return (
    <div className="line-head flex flex-col w-[1300px] h-[800px]  rounded-[24px] bg-[#363D50]">
      <div className="pt-0 px-[24px] flex flex-col customize_setting_model">
        <div className="flex justify-between items-center h-[78px] border-b-[1px] border-[#434A5D]">
          <div className="flex flex-start items-center gap-[5px]">
            <div>
              <StatusBar percentage={percentage} />
            </div>
            <div>
              <div className=" flex flex-start text-primary font-[700] text-[18px]">Data completion score</div>
              <div className=" flex flex-start text-secondary text-[#A5AEBC] text-[14px]">Service usage data completeness for the current report generation cycle</div>
            </div>
          </div>
          <div className="flex h-[40px] justify-end items-center">
            <svg
              className="cursor-pointer"
              onClick={() => {
                setPopup(false);
              }}
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="flex items-center gap-[72px] py-[48px] pl-[15px]">
      		<div className="flex flex-col items-left">
						<div className="text-primary !text-[32px] font-[700] text-left pb-1">{completeUserServiceDetails}/{totalUserServiceDetails}</div>
      			<div className="text-secondary !text-[14px] font-[400] text-left">Usage data uploaded</div>
      		</div>
      		<div className="flex flex-col flex-start">
						<div className="text-primary !text-[32px] font-[700] text-left pb-1">{upcomingReportMonth}</div>
      			<div className="text-secondary !text-[14px] font-[400] text-left">Current generation cycle</div>
      		</div>
      		<div className="flex flex-col flex-start">
						<div className="text-primary !text-[32px] font-[700] text-left pb-1">{reportDay ? `${reportDay}th` : "Manual generation"}</div>
      			<div className="text-secondary !text-[14px] font-[400] text-left">Monthly report generation date</div>
      		</div>
      	</div>
      	<div className=" flex flex-col flex-start border-[1px] border-[#434A5D] rounded-[24px]">
      		<div className="flex justify-between items-center py-[12px] px-[16px] border-b-[1px] border-[#434A5D]">
							<div className="flex items-center gap-2 h-[55px]">
      				<div>
      					<input type='checkbox' onClick={handleMasterCheckbox} className="border-[2px] border-[#ffffff] rounded-[2px] text-[#FFD74A] checked:bg-[#00000] focus:border-0 focus:ring-0 bg-[rgba(255,255,255,0)]" />
      				</div>
      				<div className="text-secondary text-[14px] font-[400]">
								<span className="text-primary text-[16px] font-[700]">{services?.length}</span> missing usage data instances
      				</div>
      			</div>
      			<div>
      				{ isAnyCheckboxChecked && 
      					<Button
							  onClick={()=>handleMultipleServiceOwner()}
							  outlined={true}
							  hoverColor={true}
							  secondary={false}
							  secondaryHoverOutlined={true}
							  fill={true}
								>
							  	<div className="flex items-center gap-2">
							    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M4.02562 1.98665C2.96467 1.48354 1.76304 2.34055 1.8928 3.50762L2.44739 8.49987H8.99986C9.276 8.49987 9.49986 8.72373 9.49986 8.99987C9.49986 9.27601 9.276 9.49987 8.99986 9.49987H2.4474L1.8928 14.4931C1.7631 15.6596 2.96428 16.5165 4.02577 16.014L15.9698 10.355C17.1134 9.81359 17.1135 8.18621 15.9699 7.64478L4.02562 1.98665ZM1.49678 8.99988L0.89892 3.61812C0.682721 1.67328 2.68493 0.244307 4.45391 1.083M4.45391 1.083L16.3978 6.74096C18.3041 7.64355 18.3042 10.3561 16.3979 11.2587L4.45394 16.9177C2.68548 17.7551 0.682664 16.328 0.898914 14.3827L1.49678 8.99988" fill="black"/>
									</svg>
							    Notify service owners
							  	</div>
								</Button>
							}
      			</div>
      		</div>
	      	<div className="px-[8px] py-[4px] overflow-y-auto h-[500px] scrollbar-hide">
						{ services?.map((item, index)=>(
		      		<div className="flex justify-between items-center py-[29px] px-[8px]" key={index}>
		      			<div className="flex items-center gap-[16px]">
		      				<div>
		      					<input
		      						type='checkbox'
		      						id={item.id}
		      						checked={checkedItems[item.id] || false}
		      						onChange={handleCheckboxChange}
		      						className="border-[2px] border-[#ffffff] rounded-[2px] text-[#FFD74A] checked:bg-[#00000] focus:border-0 focus:ring-0 bg-[rgba(255,255,255,0)]"
		      					/>
		      				</div>
		      				<div>
		      					<img
                      className="w-[40px] h-[40px] card_img rounded-lg"
                      src={item.image_url}
                      alt="service-img"
                    />
		      				</div>
		      				<div>
		      					<div className="text-primary text-left">{item.name}</div>
		      					<div className="text-secondary text-left">{item.category_name}</div>
		      				</div>
		      			</div>
		      			<div className="flex items-center gap-[30px] pr-[20px] relative">
		      				<button onClick={()=> handleSingleServiceOwner(item.id)}>
		      					<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M4.36815 1.64886C2.95354 0.978047 1.35137 2.12073 1.52438 3.67682L2.26384 10.3332H11.0005C11.3687 10.3332 11.6671 10.6316 11.6671 10.9998C11.6671 11.368 11.3687 11.6665 11.0005 11.6665H2.26385L1.52439 18.3241C1.35145 19.8795 2.95303 21.022 4.36835 20.352L20.2937 12.8067C21.8185 12.0848 21.8187 9.91495 20.2939 9.19304L4.36815 1.64886ZM0.996361 10.9998L0.199211 3.82416C-0.0890542 1.23104 2.58055 -0.674258 4.9392 0.444006M4.9392 0.444006L20.8644 7.98794C23.4061 9.1914 23.4062 12.8081 20.8646 14.0116L4.93924 21.557C2.58129 22.6734 -0.0891306 20.7707 0.199203 18.1769L0.996361 10.9998" fill="#FFD74A"/>
										</svg>
		      				</button>
										<button onClick={()=>handleUploadServiceUsageData(item.id)}>
		      					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M4.00033 23.1953C2.15938 23.1953 0.666992 21.7029 0.666992 19.862L0.666992 15.862C0.666992 15.4938 0.965469 15.1953 1.33366 15.1953C1.70185 15.1953 2.00033 15.4938 2.00033 15.862L2.00033 19.862C2.00033 20.9665 2.89576 21.862 4.00033 21.862L20.0003 21.862C21.1049 21.862 22.0003 20.9665 22.0003 19.862L22.0003 15.862C22.0003 15.4938 22.2988 15.1953 22.667 15.1953C23.0352 15.1953 23.3337 15.4938 23.3337 15.862L23.3337 19.862C23.3337 21.7029 21.8413 23.1953 20.0003 23.1953L4.00033 23.1953ZM12.0003 16.5286C11.6321 16.5286 11.3337 16.2302 11.3337 15.862L11.3337 2.80479L7.1384 7.00005C6.87805 7.2604 6.45594 7.2604 6.19559 7.00005C5.93524 6.7397 5.93524 6.31759 6.19559 6.05724L11.0575 1.19531C11.5782 0.674612 12.4224 0.674615 12.9431 1.19531L17.8051 6.05724C18.0654 6.31759 18.0654 6.7397 17.8051 7.00005C17.5447 7.2604 17.1226 7.2604 16.8623 7.00005L12.667 2.80479L12.667 15.862C12.667 16.2302 12.3685 16.5286 12.0003 16.5286Z" fill="#FFD74A"/>
										</svg>
		      				</button>
									<button
										onClick={(e) => {
									    e.stopPropagation();
									    toggleDropdown(index);
									  }}
										className="p-2"
									>
										<img src={menuDot} alt="icon"/>
		      				</button>
		      				{ dropdownOpen === index && (
				            <div
				            	className="absolute  mt-[18rem] w-90 rounded-[24px] right-[0px] z-50"
				            	ref={(el) => (dropdownRefs.current[index] = el)}
				            	onClick={(e) => e.stopPropagation()}
				            >
				              <div className="flex flex-col w-[320px] rounded-[24px] bg-[#293043] popup_body px-4 py-4">
				                <div className="flex flex-col gap-[16px] header_popup pb-4">
				                  <div className={`flex justify-between`}>
				                    <div className="flex flex-start items-center gap-[16px]">
				                      <div className="flex flex-col">
				                        <span className="text-primary !text-left">
				                          <Button
																		onClick={(e)=> {
																			e.stopPropagation();
																			handleSingleServiceOwner(item.id)
																		}}
				                          >
				                           Notify service owner 
				                          </Button>
				                        </span>
				                      </div>
				                    </div>
				                  </div>
				                </div>
				                <div className={`flex flex-col mt-4 popup_details header_popup pb-4`}>
				                  <div className="service_heading">
				                    <Button
															onClick={(e) => {
														    e.stopPropagation();
														    handleViewServiceReport(item.id);
														  }}
				                    >
				                      View service report
				                    </Button>
				                  </div>
				                </div>
				                <div className={`flex flex-col mt-4 popup_details `}>
				                  <div className="service_heading">
				                    <Button
															onClick={(e)=>{
																e.stopPropagation();
																handleManageService(item.id)
															}}
				                    >
				                      Manage service
				                    </Button>
				                  </div>
				                </div>
				              </div>
				            </div>
				          )}
		      			</div>
		      		</div>
		      	))}
		      </div>
      	</div>
      </div>

      {managePopup && (
        <BasicModal
          state={manageState}
          popup={managePopup}
          setPopup={setMangePopup}
          setState={setManageState}
          id={id}
          setId={setId}
        />
      )}

    </div>
  );
}

export default ManageDataScope;

import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Breadcrumbs from "./breadcrumbs";
import Button from "../../../../components/Buttons/button";
import { getInitials } from "../../../../shared/lib/getInitials";
import { getRandomHex } from "../../../../shared/lib/getRandomHex";

const [hex, hexAlpha] = getRandomHex();
const userInitialsColor = hex;
const userInitialsBg = hexAlpha;

function Header({ path, data }) {
  return (
    <header className="sticky top-[0px] w-[100%] z-[2] header-gradient flex flex-col gap-[16px]">
      {path && (
        <div className="w-[100%] h-[40px] flex py-[8px]">
          <Breadcrumbs path={path} />
        </div>
      )}
      <div className="w-[100%] flex flex-col gap-[16px]">
        <div className="flex flex-col md:flex-row md:justify-between pb-[8px] gap-[24px] md:items-center">
          {data?.service?.name && (
            <div className="flex flex-row justify-start gap-[24px] items-center">
              <img
                src={data?.service?.image_url}
                alt="service"
                className="w-[72px] h-[72px] card_img rounded-[16px]"
              />
              <div className="flex flex-col items-start h-[82px]">
                <span className="text-secondary !text-[14px] !leading-[21px]">
                  {data?.service?.name}
                </span>
                <div className="flex px-[2px] gap-[8px]">
                  <div className="relative pr-[40px]">
                    <svg
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.3536 17.8536C17.5488 17.6583 17.5488 17.3417 17.3536 17.1464L14.1716 13.9645C13.9763 13.7692 13.6597 13.7692 13.4645 13.9645C13.2692 14.1597 13.2692 14.4763 13.4645 14.6716L16.2929 17.5L13.4645 20.3284C13.2692 20.5237 13.2692 20.8403 13.4645 21.0355C13.6597 21.2308 13.9763 21.2308 14.1716 21.0355L17.3536 17.8536ZM0.5 0V11.5H1.5V0H0.5ZM7 18H17V17H7V18ZM0.5 11.5C0.5 15.0899 3.41015 18 7 18V17C3.96243 17 1.5 14.5376 1.5 11.5H0.5Z"
                        fill="#A5AEBC"
                      />
                    </svg>
                    <div
                      style={{
                        backgroundColor: userInitialsBg,
                        color: userInitialsColor,
                      }}
                      className={`absolute top-[1px] right-0 flex justify-center items-center font-bold text-[13px] w-[32px] h-[32px] rounded-full`}
                    >
                      {getInitials(data?.user?.name)}
                    </div>
                    <span className="absolute left-[26px] bottom-[-23px] w-max text-secondary !text-[14px] !leading-[21px] capitalize">
                      {data?.service?.category_name}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-primary !text-[24px] !leading-[36px]">
                      {data?.user?.name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!data?.service?.name && (
            <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
              <Skeleton className="!w-[150px] !h-[72px]" />
            </SkeletonTheme>
          )}
        </div>
        <div className="bg-[#41485C] w-[100%] h-px" />
      </div>
    </header>
  );
}

export default Header;

import { useState, useRef, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Button from "../../components/Buttons/button";
import savingIcon from "../../assets/icons/fluent_savings.svg";
import chevronIcon from "../../assets/icons/chevron_down.svg";
import { useDispatch, useSelector } from "react-redux";
// import { formatedAmount } from "../../utils/helpers";
import {useFormattedAmount} from "../../hooks/useFormattedAmount";
import { savingsOpportunities } from "../../services/servicesData";
import BasicModal from "../../components/models/models";
import {
  updateSavingOpportunityStatus,
  selectCompanyDashboard,
} from "../../redux/slices/companyDashboardSlice";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function SavingOpportunity({ opportunity, userId, companyName }) {
  const { savings_opportunities } = opportunity && opportunity;
  const [dropdown, setDropdown] = useState(false);
  const [confirmIgnore, setConfirmIgnore] = useState(false);
  const [opportunityId, setOpportunityId] = useState();
  const [state, setState] = useState(0);
  const [popup, setPopup] = useState(false);
  const [step, setStep] = useState(1);
  const [id, setId] = useState(false);
  const [currentSimilarServices, setCurrentSimilarServices] = useState([]);
  const [serviceData, setServiceData] = useState({});
  const dispatch = useDispatch();

  const contentRef = useRef(null);
  const carousel = useRef(null);
  const formatFunction = useFormattedAmount();

  const HandleServices = (similar_services) => {
    setCurrentSimilarServices(similar_services);
    setState(50);
    setPopup(true);
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleNext = () => {
    carousel.current.next();
  };

  const handlePrev = () => {
    carousel.current.previous();
  };

  const handleCarousel = (nextSlide, { currentSlide, onMove }) => {
    if (
      currentSlide === 2 + savings_opportunities?.length ||
      currentSlide === 2
    ) {
      setStep(1);
    } else if (currentSlide > 2) {
      setStep(currentSlide - 1);
    } else if (currentSlide < 2) {
      setStep(currentSlide + savings_opportunities?.length - 1);
    }
  };

  useEffect(() => {
    const adjustHeight = () => {
      if (contentRef.current && contentRef.current.style) {
        contentRef.current.style.height = dropdown
          ? `${contentRef.current.scrollHeight}px`
          : "0px";
      } else {
        // Keep checking until contentRef is ready
        requestAnimationFrame(adjustHeight);
      }
    };
    adjustHeight(); // Call the function immediately
    return () => cancelAnimationFrame(adjustHeight); // Cleanup
  }, [dropdown]);

  const HandleOptOut = (userId, serviceId, opportunity) => {
    setServiceData(opportunity);
    setId({ userId: userId, serviceId: serviceId });
    setPopup(true);
    setState(6);
  };

  const HandlePropose = (userId, serviceId, opportunity) => {
    setServiceData(opportunity);
    setId({ userId: userId, serviceId: serviceId });
    setPopup(true);
    setState(7);
  };
  const HandleIgnoreOpportunity = (opportunity_id) => {
    setOpportunityId(opportunity_id);
    setPopup(true);
    setState(52);
  };

  useEffect(() => {
    if (confirmIgnore) {
      dispatch(updateSavingOpportunityStatus(opportunityId));
    }
    setConfirmIgnore(false);
    setPopup(false);
  }, [dispatch,confirmIgnore]);

  useEffect(()=>{
    setStep((prevStep) => {
      const newLength = savings_opportunities?.length || 0;
      return prevStep > newLength ? 1 : prevStep;
    });
  },[savings_opportunities?.length])

  return (
    <>
      <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
        <div className="rounded-[32px] px-6 py-4 bg-grey-blue-700 custom-boxshadow mt-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="relative rounded-2xl p-2 bg-grey-blue-900">
                <img src={savingIcon} alt="savingIcon" />
                {savings_opportunities?.length !==undefined && (
                  <div className="absolute top-[-4px] right-[-4px] bg-red-400 rounded-md px-1.5 text-[11px] font-bold text-white">
                    {savings_opportunities?.length}
                  </div>
                )}
                {savings_opportunities?.length ===undefined && (
                  <div className="absolute top-[-4px] right-[-4px] rounded-md">
                    <Skeleton className="!w-4" />
                  </div>
                )}
              </div>
              <div>
                <p className="text-base font-bold text-white">
                  Savings opportunities
                </p>
                <p className="text-sm font-normal text-grey-blue-200">
                  Help {companyName ?? "your org"} save money
                </p>
              </div>
            </div>
            {dropdown ? (
              <div className="flex items-center gap-3">
                <div className="flex items-center gap-2">
                  <button
                    onClick={handlePrev}
                    className="p-2.5 rounded-2xl group border border-solid border-transparent hover:border-[#A5AEBC]"
                    disabled={savings_opportunities?.length == 1 ? true : false}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.3852 3.57854C13.7758 3.96906 13.7758 4.60223 13.3852 4.99275L8.55664 9.82136L13.3852 14.65C13.7758 15.0405 13.7758 15.6737 13.3852 16.0642C12.9947 16.4547 12.3616 16.4547 11.971 16.0642L7.14243 11.2356C6.36138 10.4545 6.36138 9.18819 7.14243 8.40714L11.971 3.57854C12.3616 3.18801 12.9947 3.18801 13.3852 3.57854Z"
                        fill={`${
                          savings_opportunities?.length == 1
                            ? "#A5AEBC"
                            : "white"
                        }`}
                        className={`${
                          savings_opportunities?.length == 1
                            ? null
                            : "group-hover:fill-[#FFD74A]"
                        }`}
                      />
                    </svg>
                  </button>
                  {savings_opportunities?.length !== undefined && (
                    <span className="min-w-8 text-sm font-normal text-white">
                      {step}/{savings_opportunities?.length}
                    </span>
                  )}
                  {savings_opportunities?.length === undefined && (
                    <span className="min-w-8">
                      <Skeleton />
                    </span>
                  )}
                  <button
                    onClick={handleNext}
                    className="p-2.5 rounded-2xl group border border-solid border-transparent hover:border-[#A5AEBC]"
                    disabled={savings_opportunities?.length == 1 ? true : false}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.61475 3.57854C6.22423 3.96906 6.22423 4.60223 6.61475 4.99275L11.4434 9.82136L6.61475 14.65C6.22423 15.0405 6.22423 15.6737 6.61475 16.0642C7.00528 16.4547 7.63844 16.4547 8.02897 16.0642L12.8576 11.2356C13.6386 10.4545 13.6386 9.18819 12.8576 8.40714L8.02897 3.57854C7.63844 3.18801 7.00528 3.18801 6.61475 3.57854Z"
                        fill={`${
                          savings_opportunities?.length == 1
                            ? "#A5AEBC"
                            : "white"
                        }`}
                        className={`${
                          savings_opportunities?.length == 1
                            ? null
                            : "group-hover:fill-[#FFD74A]"
                        }`}
                      />
                    </svg>
                  </button>
                </div>
                <button
                  onClick={handleDropdown}
                  className={`transition duration-300 transform ${
                    dropdown ? "rotate-180" : ""
                  }`}
                >
                  <img src={chevronIcon} alt="chevronIcon" />
                </button>
              </div>
            ) : (
              <div className="flex items-center gap-3">
                <div className="text-right">
                  {opportunity?.total_savings !== undefined && (
                    <p className="text-xl font-bold text-gold-400">
                      {formatFunction(opportunity?.total_savings)}
                    </p>
                  )}
                  {opportunity?.total_savings === undefined && (
                    <p className="w-[124px]">
                      <Skeleton />
                    </p>
                  )}
                  <p className="text-sm font-normal text-grey-blue-200">
                    Est yearly savings
                  </p>
                </div>
                <button
                  onClick={handleDropdown}
                  className={`transition duration-300 transform ${
                    dropdown ? "rotate-180" : ""
                  }`}
                >
                  <img src={chevronIcon} alt="chevronIcon" />
                </button>
              </div>
            )}
          </div>
          <div
            ref={contentRef}
            className="transition-all duration-300 overflow-hidden"
          >
            {savings_opportunities?.length > 0 && (
              <Carousel
                ref={carousel}
                responsive={responsive}
                arrows={false}
                infinite={true}
                afterChange={handleCarousel}
              >
                {savings_opportunities?.map((opportunity, index) => {
                  return (
                    <div
                      key={index}
                      className="mt-4 rounded-3xl border border-solid border-[#3A475B]"
                    >
                      <div className="flex items-center justify-between gap-8">
                        <div className="pl-4 py-4">
                          <div className="flex items-center gap-1">
                            <div className="rounded-full w-2 h-2 bg-[#ED4650]"></div>
                            <span className="text-[11px] font-semibold text-grey-blue-200">
                              Opportunity #{index + 1}
                            </span>
                          </div>
                          <div className="mt-4 flex items-center justify-between gap-4">
                            {opportunity.image && (
                              <img
                                src={opportunity.image}
                                className="h-[72px]"
                                alt="image1"
                              />
                            )}
                            <div>
                              <p className="text-lg font-semibold text-white">
                                {opportunity.title}
                              </p>
                              <p className="text-sm font-normal text-grey-blue-200">
                                {opportunity.description}
                              </p>
                            </div>
                          </div>
                          <div className="mt-4 flex items-center gap-6">
                            {opportunity.optOut && (
                              <Button
                                className={
                                  "mt-0.5 mb-0.5 mx-0.5 hover:mx-0 hover:mt-0 hover:mb-0 hover:px-[22px] hover:py-[14px]"
                                }
                                outlined={true}
                                secondary={true}
                                onClick={() =>
                                  HandleOptOut(
                                    userId,
                                    opportunity?.service_id,
                                    opportunity
                                  )
                                }
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="transition transform group-hover:scale-125"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3.5 7C3.5 6.17157 4.17157 5.5 5 5.5H19C19.8284 5.5 20.5 6.17157 20.5 7V15C20.5 15.8284 19.8284 16.5 19 16.5H14.8284C14.1654 16.5 13.5295 16.7634 13.0607 17.2322L9.14645 21.1464C8.95118 21.3417 8.95118 21.6583 9.14645 21.8536C9.34171 22.0488 9.65829 22.0488 9.85355 21.8536L13.7678 17.9393C14.0491 17.658 14.4306 17.5 14.8284 17.5H19C20.3807 17.5 21.5 16.3807 21.5 15V7C21.5 5.61929 20.3807 4.5 19 4.5H5C3.61929 4.5 2.5 5.61929 2.5 7V15C2.5 16.3807 3.61929 17.5 5 17.5H8.5C8.77614 17.5 9 17.2761 9 17C9 16.7239 8.77614 16.5 8.5 16.5H5C4.17157 16.5 3.5 15.8284 3.5 15V7ZM8 10.5C7.72386 10.5 7.5 10.7239 7.5 11C7.5 11.2761 7.72386 11.5 8 11.5H8.01C8.28614 11.5 8.51 11.2761 8.51 11C8.51 10.7239 8.28614 10.5 8.01 10.5H8ZM12 10.5C11.7239 10.5 11.5 10.7239 11.5 11C11.5 11.2761 11.7239 11.5 12 11.5H12.01C12.2861 11.5 12.51 11.2761 12.51 11C12.51 10.7239 12.2861 10.5 12.01 10.5H12ZM16 10.5C15.7239 10.5 15.5 10.7239 15.5 11C15.5 11.2761 15.7239 11.5 16 11.5H16.01C16.2861 11.5 16.51 11.2761 16.51 11C16.51 10.7239 16.2861 10.5 16.01 10.5H16Z"
                                    fill="#FFD74A"
                                  />
                                </svg>
                                Opt out
                              </Button>
                            )}
                            {opportunity.viewServices && (
                              <Button
                                className={
                                  "mt-0.5 mb-0.5 mx-0.5 hover:mx-0 hover:mt-0 hover:mb-0 hover:px-[22px] hover:py-[14px]"
                                }
                                outlined={true}
                                secondary={true}
                                onClick={() =>
                                  HandleServices(opportunity?.similar_services)
                                }
                              >
                                View {opportunity.similar_services_count + 1}{" "}
                                services
                              </Button>
                            )}
                            {opportunity.proposeChange && (
                              <Button
                                className={"mt-0.5 mb-0.5 mx-0.5"}
                                secondary={true}
                                onClick={() =>
                                  HandlePropose(
                                    userId,
                                    opportunity?.service_id,
                                    opportunity
                                  )
                                }
                              >
                                Propose change
                              </Button>
                            )}
                            <Button
                              className={
                                "mt-0.5 mb-0.5 mx-0.5 !font-normal !text-grey-blue-200"
                              }
                              secondaryHover={true}
                              onClick={() =>
                                HandleIgnoreOpportunity(opportunity.id)
                              }
                            >
                              Ignore opportunity
                            </Button>
                          </div>
                        </div>
                        <div className="min-w-[225px] flex-1 rounded-r-3xl pt-1 pb-2 py-2 bg-[#3A475B]">
                          <div className="w-full py-[45px]">
                            <p className="text-[11px] font-semibold text-grey-blue-200 text-center">
                              EST Yearly savings
                            </p>
                            <div className="w-full mt-2 flex items-start justify-center gap-0.5">
                              <span className="text-sm font-normal text-gold-400">
                              </span>
                              <span className="text-4xl font-bold text-gold-400">
                                {formatFunction(opportunity.yearlyPrice)}
                              </span>
                            </div>
                            <p className="mt-2 text-xs font-bold text-gold-400 text-center">
                              /{formatFunction(opportunity.totalPrice)}
                            </p>
                          </div>
                          {/*<p className="text-xs font-normal text-grey-blue-300 text-center">
                        <span className="text-white">
                          {opportunity.percentage}%
                        </span>{" "}
                        of your yearly cost
                      </p>*/}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
      </SkeletonTheme>

      {popup && (
        <BasicModal
          similarServices={currentSimilarServices}
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          userId={userId}
          id={id}
          serviceData={serviceData}
          opportunity={opportunity}
          setConfirmIgnore={setConfirmIgnore}
        />
      )}
    </>
  );
}

export default SavingOpportunity;

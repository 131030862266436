import { useState, useEffect } from "react";
import BasicModal from "../../../../components/models/models";
import Table from "../../../../components/Table/table";
import Header from "./components/header";
import PillCards from "./components/pill-cards";

import { heading, tableData } from "../../../../services/expensesTableData";

const initialState = {
  category: false,
  instances: false,
  cost: false,
};

function ExpensesModule() {
  const [popup, setPopup] = useState(false);
  const [btns, setBtns] = useState(initialState);
  const [active, setActive] = useState("service_name");
  const [orderType, setOrderType] = useState("asc");
  const [state, setState] = useState(0);
  const [id, setId] = useState(null);

  const [data, setData] = useState([...tableData]);

  const transformedData = transformResponse(tableData);

  function transformResponse(response = []) {
    return response?.map((item) => {
      return {
        id: item.id,
        category: item.category,
        instances: item.instances,
        cost: item.cost,
      };
    });
  }

  useEffect(() => {
    setData([...tableData]);
  }, []);

  const handleExpensesCategory = (orderType, set) => {
    setBtns({
      ...btns,
      type: true,
      [active !== "category" && active]: false,
    });
    setActive("category");
    const sort = [...data].sort((a, b) => {
      const serviceA = a.category || "";
      const serviceB = b.category || "";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });
    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleExpensesInstances = (orderType, set) => {
    setBtns({
      ...btns,
      type: true,
      [active !== "instances" && active]: false,
    });
    setActive("instances");
    const sort = [...data].sort((a, b) => {
      const serviceA = a.instances || 0;
      const serviceB = b.instances || 0;
      if (orderType === "asc") {
        return serviceB - serviceA;
      } else {
        return serviceA - serviceB;
      }
    });
    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleExpensesCost = (orderType, set) => {
    setBtns({
      ...btns,
      type: true,
      [active !== "cost" && active]: false,
    });
    setActive("cost");
    const sort = [...data].sort((a, b) => {
      const serviceA = a.cost || 0;
      const serviceB = b.cost || 0;
      if (orderType === "asc") {
        return serviceB - serviceA;
      } else {
        return serviceA - serviceB;
      }
    });
    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  return (
    <div className="right_panel flex flex-col w-[100%] gap-[32px] pt-[20px] xl:pt-[32px] xl:max-w-[1099px] 6xl:max-w-[80%] pr-[24px]">
      <Header
        title={"Expenses"}
        subHeading={"Data updated: 00/00/00"}
      />

      <PillCards totalMonthlyCost={232895} averageMonthlyCost={298988} />

      <div className="card-section">
        <div className="cards_wrap double_space bg-[#363D50] w-[100%] rounded-[24px] overflow-y-scroll scrollbar-hide max-h-[470px] md:max-h-[670px]">
          <div className="p-6 pb-2 flex items-center gap-4">
            <div className="p-2 rounded-2xl bg-[#242B3E]">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.6665 8.00163C4.6665 6.1611 6.15846 4.66699 7.99984 4.66699H23.9998C25.8412 4.66699 27.3332 6.1611 27.3332 8.00163V26.7531C27.3332 27.8154 26.1486 28.4563 25.2602 27.864L23.1079 26.4292C22.8664 26.2681 22.5484 26.2819 22.3217 26.4633L20.5826 27.8546C19.8521 28.4389 18.8142 28.4389 18.0838 27.8546L16.4163 26.5206C16.1728 26.3258 15.8269 26.3258 15.5834 26.5206L13.9159 27.8546C13.1855 28.4389 12.1476 28.4389 11.4171 27.8546L9.67802 26.4633C9.45132 26.2819 9.13332 26.2681 8.89176 26.4292L6.73944 27.864C5.85112 28.4563 4.6665 27.8154 4.6665 26.7531V8.00163ZM7.99984 6.00033C6.89569 6.00033 5.99984 6.89664 5.99984 8.00163V26.7531L8.15216 25.3198C8.87684 24.8366 9.83085 24.878 10.5109 25.4221L12.25 26.8134C12.4935 27.0082 12.8395 27.0082 13.083 26.8134L14.7504 25.4794C15.4809 24.8951 16.5188 24.8951 17.2492 25.4794L18.9167 26.8134C19.1602 27.0082 19.5062 27.0082 19.7496 26.8134L21.4887 25.4221C22.1688 24.878 23.1228 24.8366 23.8475 25.3198L25.9992 26.7542L25.9998 8.00163C25.9998 6.89664 25.104 6.00033 23.9998 6.00033H7.99984ZM9.99984 12.0003C9.99984 11.6321 10.2983 11.3337 10.6665 11.3337H16.6665C17.0347 11.3337 17.3332 11.6321 17.3332 12.0003C17.3332 12.3685 17.0347 12.667 16.6665 12.667H10.6665C10.2983 12.667 9.99984 12.3685 9.99984 12.0003ZM19.9998 12.0003C19.9998 11.6321 20.2983 11.3337 20.6665 11.3337H21.3332C21.7014 11.3337 21.9998 11.6321 21.9998 12.0003C21.9998 12.3685 21.7014 12.667 21.3332 12.667H20.6665C20.2983 12.667 19.9998 12.3685 19.9998 12.0003ZM9.99984 17.3337C9.99984 16.9655 10.2983 16.667 10.6665 16.667H21.3332C21.7014 16.667 21.9998 16.9655 21.9998 17.3337C21.9998 17.7018 21.7014 18.0003 21.3332 18.0003H10.6665C10.2983 18.0003 9.99984 17.7018 9.99984 17.3337Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <p className="text-base font-bold text-white">Expenses</p>
              <p className="text-secondary font-light">
                Expenses reported by your company
              </p>
            </div>
          </div>
          <Table
            headings={heading}
            tableData={data}
            setState={setState}
            setId={setId}
            setPopup={setPopup}
            table={"expenses"}
            handleExpensesCategory={handleExpensesCategory}
            handleExpensesInstances={handleExpensesInstances}
            handleExpensesCost={handleExpensesCost}
            orderType={orderType}
          />
        </div>
      </div>

      {setPopup && (
        <BasicModal
          state={state}
          id={id}
          setId={setId}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          tablename="personaldashborad"
        />
      )}
    </div>
  );
}

export default ExpensesModule;

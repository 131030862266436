import React from "react";
import Button from "../../../../components/Buttons/button";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Pill from "../../../../components/Pill/pill";
import { formatDate } from "../../feedback-module/feedbackModule";
import cn from "classnames";

import negativeIcon from "./../../../../assets/images/negative.png";
import negativeHoverIcon from "./../../../../assets/images/nagative_hover.png";
import neutralIcon from "./../../../../assets/images/neutral.png";
import neutralHoverIcon from "./../../../../assets/images/neutral_hover.png";
import smileyIcon from "./../../../../assets/images/smiley.png";
import smileyHoverIcon from "./../../../../assets/images/smiley_hover.png";

function ServicesRequestAndFeedbackCards({
  setPopup,
  setState,
  data,
  setFeedbackID,
  setReqId,
  setDashboard,
}) {
  const date = formatDate(data?.feedback?.updated_at);

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-10 justify-center">
      <div className="flex flex-col bg-[#363D50] rounded-[24px] pb-[16px]  max-w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[12px] rounded-[16px] w-14 h-14 gap-[10px]">
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.16634 9.33333C5.16634 8.22876 6.06177 7.33333 7.16634 7.33333H25.833C26.9376 7.33333 27.833 8.22876 27.833 9.33333V20C27.833 21.1046 26.9376 22 25.833 22H20.2709C19.3869 22 18.539 22.3512 17.9139 22.9763L12.6949 28.1953C12.4346 28.4556 12.4346 28.8777 12.6949 29.1381C12.9553 29.3984 13.3774 29.3984 13.6377 29.1381L18.8567 23.9191C19.2318 23.544 19.7405 23.3333 20.2709 23.3333H25.833C27.674 23.3333 29.1663 21.8409 29.1663 20V9.33333C29.1663 7.49238 27.674 6 25.833 6H7.16634C5.32539 6 3.83301 7.49238 3.83301 9.33333V20C3.83301 21.8409 5.32539 23.3333 7.16634 23.3333H11.833C12.2012 23.3333 12.4997 23.0349 12.4997 22.6667C12.4997 22.2985 12.2012 22 11.833 22H7.16634C6.06177 22 5.16634 21.1046 5.16634 20V9.33333ZM11.1663 14C10.7982 14 10.4997 14.2985 10.4997 14.6667C10.4997 15.0349 10.7982 15.3333 11.1663 15.3333H11.1797C11.5479 15.3333 11.8463 15.0349 11.8463 14.6667C11.8463 14.2985 11.5479 14 11.1797 14H11.1663ZM16.4997 14C16.1315 14 15.833 14.2985 15.833 14.6667C15.833 15.0349 16.1315 15.3333 16.4997 15.3333H16.513C16.8812 15.3333 17.1797 15.0349 17.1797 14.6667C17.1797 14.2985 16.8812 14 16.513 14H16.4997ZM21.833 14C21.4648 14 21.1663 14.2985 21.1663 14.6667C21.1663 15.0349 21.4648 15.3333 21.833 15.3333H21.8463C22.2145 15.3333 22.513 15.0349 22.513 14.6667C22.513 14.2985 22.2145 14 21.8463 14H21.833Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Your Service Requests
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Action you’ve asked your company to take
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[270px] px-[16px] pt-1 overflow-y-scroll scrollbar-hide">
          {data?.requests?.map((item) => (
            <div className="flex flex-col px-[8px]" key={item?.id}>
              <div
                onClick={() => {
                  setPopup(true);
                  setState(14);
                  setReqId(item.id);
                  setDashboard("personal_dashboard_request");
                }}
                className="flex flex-col card-animate border-t-[1px] border-[#41485C] hover:rounded-[8px] cursor-pointer"
              >
                <div className="flex flex-col h-[90px] px-[3px] gap-[8px] justify-center">
                  <div className="flex flex-row justify-between gap-[4px]">
                    <Pill fill={true}>{item?.request_type}</Pill>
                    <div className="flex flex-row gap-[8px] items-center">
                      <div className="flex flex-row gap-[8px] items-center">
                        <span className="text-secondary">
                          {item?.comments_count == 0
                            ? "-"
                            : item.comments_count}
                        </span>
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.75 1.25C7.0266 1.25165 5.37426 1.93701 4.15563 3.15563C2.93701 4.37426 2.25165 6.0266 2.25 7.75V13.0188C2.25 13.3453 2.37972 13.6585 2.61062 13.8894C2.84153 14.1203 3.1547 14.25 3.48125 14.25H8.75C10.4739 14.25 12.1272 13.5652 13.3462 12.3462C14.5652 11.1272 15.25 9.47391 15.25 7.75C15.25 6.02609 14.5652 4.37279 13.3462 3.15381C12.1272 1.93482 10.4739 1.25 8.75 1.25ZM8.75 12.75H3.75V7.75C3.75 6.76109 4.04324 5.79439 4.59265 4.97215C5.14206 4.1499 5.92295 3.50904 6.83658 3.1306C7.75021 2.75216 8.75555 2.65315 9.72545 2.84607C10.6954 3.039 11.5863 3.5152 12.2855 4.21447C12.9848 4.91373 13.461 5.80464 13.6539 6.77455C13.8469 7.74445 13.7478 8.74979 13.3694 9.66342C12.991 10.577 12.3501 11.3579 11.5279 11.9073C10.7056 12.4568 9.73891 12.75 8.75 12.75ZM11.25 6.75C11.25 6.94891 11.171 7.13968 11.0303 7.28033C10.8897 7.42098 10.6989 7.5 10.5 7.5H6.75C6.55109 7.5 6.36032 7.42098 6.21967 7.28033C6.07902 7.13968 6 6.94891 6 6.75C6 6.55109 6.07902 6.36032 6.21967 6.21967C6.36032 6.07902 6.55109 6 6.75 6H10.5C10.6989 6 10.8897 6.07902 11.0303 6.21967C11.171 6.36032 11.25 6.55109 11.25 6.75ZM11.25 9.25C11.25 9.44891 11.171 9.63968 11.0303 9.78033C10.8897 9.92098 10.6989 10 10.5 10H6.75C6.55109 10 6.36032 9.92098 6.21967 9.78033C6.07902 9.63968 6 9.44891 6 9.25C6 9.05109 6.07902 8.86032 6.21967 8.71967C6.36032 8.57902 6.55109 8.5 6.75 8.5H10.5C10.6989 8.5 10.8897 8.57902 11.0303 8.71967C11.171 8.86032 11.25 9.05109 11.25 9.25Z"
                            fill="#A5AEBC"
                          />
                        </svg>
                        <span className="text-secondary">
                          {item?.requested_on}
                        </span>
                      </div>
                      {item?.status === "open" ? (
                        <Pill greenFill={true} className="text-[12px] py-[4px]">
                          {item?.status}
                        </Pill>
                      ) : (
                        <Pill redFill={true} className="text-[12px] py-[4px]">
                          {item?.status}
                        </Pill>
                      )}
                    </div>
                  </div>
                  <span className="px-1 text-secondary !leading-[20px]">
                    {item?.description}
                  </span>
                </div>
              </div>
            </div>
          ))}
          {!data?.requests && (
            <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
              <Skeleton count={3} />
            </SkeletonTheme>
          )}
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Link to={"/personal_dashboard/requests"}>
            <Button
              className={cn({
                invisible: !data?.requests?.length,
              })}
              secondary
              hoverColor
            >
              View all
            </Button>
          </Link>
        </div>
      </div>

      <div className="flex flex-col bg-[#363D50] rounded-[24px] pb-[16px]  max-w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[12px] rounded-[16px] w-14 h-14 gap-[10px]">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.24461 9.16792C10.061 8.66032 11.0568 8.52991 11.9764 8.81018L18.683 10.8542C19.6027 11.1345 20.0557 12.169 19.6379 13.035C19.3128 13.7087 18.557 14.0588 17.8329 13.8712L14.4674 12.9994C14.3997 12.9811 14.3312 12.974 14.264 12.9771C14.062 12.9862 13.8747 13.0876 13.7562 13.2495C13.7127 13.3089 13.6785 13.3765 13.6562 13.4507C13.6337 13.5248 13.6249 13.6 13.6283 13.6736C13.6405 13.9433 13.817 14.1866 14.0825 14.2753L16.7628 15.2012C17.655 15.5093 18.6196 15.5397 19.5294 15.2883L26.6989 13.3072C27.2024 13.168 27.7393 13.3564 28.0455 13.7795C28.4569 14.3479 28.3205 15.1434 27.7432 15.5422L19.3132 21.3658C18.5011 21.9269 17.4822 22.0991 16.5308 21.8362L9.20468 19.8118C8.79984 19.6999 8.38174 19.6432 7.96173 19.6432H4.6441C4.27591 19.6432 3.97743 19.9417 3.97743 20.3099C3.97743 20.6781 4.27591 20.9766 4.6441 20.9766H7.96173C8.26174 20.9766 8.56038 21.0171 8.84955 21.097L16.1756 23.1214C17.5076 23.4894 18.9341 23.2483 20.0711 22.4629L28.501 16.6393C29.6936 15.8154 29.9755 14.1719 29.1256 12.9977C28.493 12.1236 27.3838 11.7346 26.3438 12.022L20.8766 13.5327C21.5833 11.9475 20.7449 10.0887 19.0718 9.57878L12.3651 7.53477C11.0777 7.14239 9.68358 7.32496 8.5406 8.0356L3.64874 11.0771C3.33606 11.2715 3.24017 11.6826 3.43458 11.9952C3.62899 12.3079 4.04007 12.4038 4.35275 12.2094L9.24461 9.16792Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Your Service Feedback
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Feedback you’ve given about this service
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[270px] px-[16px] pt-1 overflow-y-scroll scrollbar-hide">
          {data?.feedback?.map((item) => (
            <div className="flex flex-col px-[8px]" key={item.id}>
              <div
                onClick={() => {
                  setPopup(true);
                  setState(13);
                  setFeedbackID(item.id);
                }}
                className="flex flex-col card-animate border-t-[1px] border-[#41485C] hover:rounded-[8px] cursor-pointer"
              >
                {/* <div className='h-[1px] bg-[#41485C] w-[100%]' /> */}

                <div className="flex flex-col py-[10px] px-[3px] gap-[8px] justify-center">
                  <div className="flex flex-row justify-between gap-[4px]">
                    <div>
                      {item?.rating === "positive" &&
                        (item?.rating === "positive" ? (
                          <img
                            src={smileyHoverIcon}
                            alt="rating-img"
                            className="rating_icon active w-[24px]"
                          />
                        ) : (
                          <img
                            src={smileyIcon}
                            alt="rating-img"
                            className="rating_icon inactive w-[24px]"
                          />
                        ))}

                      {item?.rating === "general" &&
                        (item?.rating === "general" ? (
                          <img
                            src={neutralHoverIcon}
                            alt="rating-img"
                            className="rating_icon active w-[24px]"
                          />
                        ) : (
                          <img
                            src={neutralIcon}
                            alt="rating-img"
                            className="rating_icon inactive w-[24px]"
                          />
                        ))}

                      {item?.rating === "negative" &&
                        (item?.rating === "negative" ? (
                          <img
                            src={negativeHoverIcon}
                            alt="rating-img"
                            className="rating_icon active w-[24px]"
                          />
                        ) : (
                          <img
                            src={negativeIcon}
                            alt="rating-img"
                            className="rating_icon inactive w-[24px]"
                          />
                        ))}
                    </div>
                    <div className="flex flex-row gap-[8px] items-center">
                      <div className="flex flex-row gap-[8px] items-center">
                        <span className="text-secondary">
                          {" "}
                          {item?.feedback_on}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className="text-secondary !leading-[20px]">
                    {item?.feedback_details}
                  </span>
                </div>
              </div>
            </div>
          ))}
          {!data?.feedback && (
            <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
              <Skeleton count={3} />
            </SkeletonTheme>
          )}
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Link to={"/personal_dashboard/feedbacks"}>
            <Button
              className={cn({
                invisible: !data?.feedback?.length,
              })}
              secondary
              hoverColor
            >
              View all
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ServicesRequestAndFeedbackCards;

import React from "react";
import Button from "../../../../../components/Buttons/button";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getUsageFeedbackData,
  getUsageTeamFeedbackData,
} from "../../../../../redux/slices/companyDashboardSlice";

function NegativeFeedbackCard({
  setPopup,
  setState,
  feedback,
  setFeedbackID,
  id,
  userId,
  serviceId,
  setDashboard,
}) {
  const dispatch = useDispatch();
  return (
    <div className="grid grid-cols-1 gap-[48px] justify-center">
      <div className="flex flex-col bg-[#363D50] rounded-[24px] pb-[16px]  max-w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.66634 9.33333C4.66634 8.22876 5.56177 7.33333 6.66634 7.33333H25.333C26.4376 7.33333 27.333 8.22876 27.333 9.33333V20C27.333 21.1046 26.4376 22 25.333 22H19.7709C18.8869 22 18.039 22.3512 17.4139 22.9763L12.1949 28.1953C11.9346 28.4556 11.9346 28.8777 12.1949 29.1381C12.4553 29.3984 12.8774 29.3984 13.1377 29.1381L18.3567 23.9191C18.7318 23.544 19.2405 23.3333 19.7709 23.3333H25.333C27.174 23.3333 28.6663 21.8409 28.6663 20V9.33333C28.6663 7.49238 27.174 6 25.333 6H6.66634C4.82539 6 3.33301 7.49238 3.33301 9.33333V20C3.33301 21.8409 4.82539 23.3333 6.66634 23.3333H11.333C11.7012 23.3333 11.9997 23.0349 11.9997 22.6667C11.9997 22.2985 11.7012 22 11.333 22H6.66634C5.56177 22 4.66634 21.1046 4.66634 20V9.33333ZM10.6663 14C10.2982 14 9.99967 14.2985 9.99967 14.6667C9.99967 15.0349 10.2982 15.3333 10.6663 15.3333H10.6797C11.0479 15.3333 11.3463 15.0349 11.3463 14.6667C11.3463 14.2985 11.0479 14 10.6797 14H10.6663ZM15.9997 14C15.6315 14 15.333 14.2985 15.333 14.6667C15.333 15.0349 15.6315 15.3333 15.9997 15.3333H16.013C16.3812 15.3333 16.6797 15.0349 16.6797 14.6667C16.6797 14.2985 16.3812 14 16.013 14H15.9997ZM21.333 14C20.9648 14 20.6663 14.2985 20.6663 14.6667C20.6663 15.0349 20.9648 15.3333 21.333 15.3333H21.3463C21.7145 15.3333 22.013 15.0349 22.013 14.6667C22.013 14.2985 21.7145 14 21.3463 14H21.333Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Negative Feedback
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Feedback that can lead to oppertunity
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[360px] overflow-y-scroll scrollbar-hide px-[16px]">
          <div className="flex flex-col px-[8px]">
            {feedback?.map((item) => (
              <div
                onClick={() => {
                  // setPopup(true);
                  // setState(13);
                  setFeedbackID(item.id);
                  setDashboard("companyDahboardfeedback");
                }}
                key={item.id}
                className="flex flex-col card-animate border-t-[1px] border-[#41485C] hover:rounded-[8px] cursor-pointer"
              >
                {/* <div className='h-[1px] bg-[#41485C] w-[100%]' /> */}
                <div className="flex flex-col py-[10px] px-[3px] gap-[8px] justify-center">
                  <div className="flex flex-row justify-between gap-[4px]">
                    <div>
                      {item.feedback_rating === "positive" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.1667 3.5C7.3802 3.5 3.5 7.3802 3.5 12.1667C3.5 16.9531 7.3802 20.8333 12.1667 20.8333C16.9531 20.8333 20.8333 16.9531 20.8333 12.1667C20.8333 7.3802 16.9531 3.5 12.1667 3.5ZM2.5 12.1667C2.5 6.82791 6.82791 2.5 12.1667 2.5C17.5054 2.5 21.8333 6.82791 21.8333 12.1667C21.8333 17.5054 17.5054 21.8333 12.1667 21.8333C6.82791 21.8333 2.5 17.5054 2.5 12.1667ZM8.5 8.87085C8.77614 8.87085 9 9.09471 9 9.37085V9.46252C9 9.73866 8.77614 9.96252 8.5 9.96252C8.22386 9.96252 8 9.73866 8 9.46252V9.37085C8 9.09471 8.22386 8.87085 8.5 8.87085ZM15.8333 8.87085C16.1095 8.87085 16.3333 9.09471 16.3333 9.37085V9.46252C16.3333 9.73866 16.1095 9.96252 15.8333 9.96252C15.5572 9.96252 15.3333 9.73866 15.3333 9.46252V9.37085C15.3333 9.09471 15.5572 8.87085 15.8333 8.87085ZM8.97434 13.8419C8.88702 13.5799 8.60386 13.4383 8.34189 13.5257C8.07991 13.613 7.93833 13.8961 8.02566 14.1581C8.53003 15.6712 9.86333 17.25 12.1667 17.25C14.47 17.25 15.8033 15.6712 16.3077 14.1581C16.395 13.8961 16.2534 13.613 15.9914 13.5257C15.7295 13.4383 15.4463 13.5799 15.359 13.8419C14.9467 15.0788 13.915 16.25 12.1667 16.25C10.4183 16.25 9.38664 15.0788 8.97434 13.8419Z"
                            fill="white"
                          />
                        </svg>
                      )}

                      {item.feedback_rating === "general" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.5 12.1667C3.5 7.3802 7.3802 3.5 12.1667 3.5C16.9531 3.5 20.8333 7.3802 20.8333 12.1667C20.8333 16.9531 16.9531 20.8333 12.1667 20.8333C7.3802 20.8333 3.5 16.9531 3.5 12.1667ZM12.1667 2.5C6.82791 2.5 2.5 6.82791 2.5 12.1667C2.5 17.5054 6.82791 21.8333 12.1667 21.8333C17.5054 21.8333 21.8333 17.5054 21.8333 12.1667C21.8333 6.82791 17.5054 2.5 12.1667 2.5ZM9 9.37085C9 9.09471 8.77614 8.87085 8.5 8.87085C8.22386 8.87085 8 9.09471 8 9.37085V9.46252C8 9.73866 8.22386 9.96252 8.5 9.96252C8.77614 9.96252 9 9.73866 9 9.46252V9.37085ZM16.3333 9.37085C16.3333 9.09471 16.1095 8.87085 15.8333 8.87085C15.5572 8.87085 15.3333 9.09471 15.3333 9.37085V9.46252C15.3333 9.73866 15.5572 9.96252 15.8333 9.96252C16.1095 9.96252 16.3333 9.73866 16.3333 9.46252V9.37085ZM8.5 13.5C8.22386 13.5 8 13.7239 8 14C8 14.2762 8.22386 14.5 8.5 14.5H15.8333C16.1095 14.5 16.3333 14.2762 16.3333 14C16.3333 13.7239 16.1095 13.5 15.8333 13.5H8.5Z"
                            fill="white"
                          />
                        </svg>
                      )}

                      {item.feedback_rating === "negative" && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.1667 3.5C7.3802 3.5 3.5 7.3802 3.5 12.1667C3.5 16.9531 7.3802 20.8333 12.1667 20.8333C16.9531 20.8333 20.8333 16.9531 20.8333 12.1667C20.8333 7.3802 16.9531 3.5 12.1667 3.5ZM2.5 12.1667C2.5 6.82791 6.82791 2.5 12.1667 2.5C17.5054 2.5 21.8333 6.82791 21.8333 12.1667C21.8333 17.5054 17.5054 21.8333 12.1667 21.8333C6.82791 21.8333 2.5 17.5054 2.5 12.1667ZM8.5 8.87085C8.77614 8.87085 9 9.09471 9 9.37085V9.46252C9 9.73866 8.77614 9.96252 8.5 9.96252C8.22386 9.96252 8 9.73866 8 9.46252V9.37085C8 9.09471 8.22386 8.87085 8.5 8.87085ZM15.8333 8.87085C16.1095 8.87085 16.3333 9.09471 16.3333 9.37085V9.46252C16.3333 9.73866 16.1095 9.96252 15.8333 9.96252C15.5572 9.96252 15.3333 9.73866 15.3333 9.46252V9.37085C15.3333 9.09471 15.5572 8.87085 15.8333 8.87085ZM12.1667 12.5834C9.86333 12.5834 8.53003 14.1622 8.02566 15.6753C7.93833 15.9372 8.07991 16.2204 8.34189 16.3077C8.60386 16.395 8.88702 16.2535 8.97434 15.9915C9.38664 14.7546 10.4183 13.5834 12.1667 13.5834C13.915 13.5834 14.9467 14.7546 15.359 15.9915C15.4463 16.2535 15.7295 16.395 15.9914 16.3077C16.2534 16.2204 16.395 15.9372 16.3077 15.6753C15.8033 14.1622 14.47 12.5834 12.1667 12.5834Z"
                            fill="white"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="flex flex-row gap-[8px] items-center">
                      <div className="flex flex-row gap-[8px] items-center">
                        <span className="text-secondary">
                          {item?.feedback_date}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className="text-secondary !leading-[20px]">
                    {item?.feedback_details}
                  </span>
                </div>
              </div>
            ))}
            {!feedback && (
              <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
                <Skeleton count={3} />
              </SkeletonTheme>
            )}
          </div>
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Button
            onClick={() => {
              setPopup(true);
              setState(21);
              // dispatch(getUsageFeedbackData(id));
              dispatch(getUsageTeamFeedbackData(id, userId));
            }}
            secondary={true}
            hoverColor={true}
          >
            View all
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NegativeFeedbackCard;

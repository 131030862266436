import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { logInRequest, selectUser, setLoading } from '../../redux/slices/userSlice';
import {toast} from 'react-toastify';
import SuccessIcon from "../../assets/icons/success-check.svg";

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation()
 
    const dispatch = useDispatch()
    const user = useSelector(selectUser)

  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
  
      if (email === '') {
        setErrorMessage('* Email is required');
      } else if (password === '') {
        setErrorMessage('* Password is required');
      } else {
        // Perform login logic here
        dispatch(setLoading(true))
        localStorage.setItem('logInCalled', 'true')
        dispatch(logInRequest({
          email: email,
          password: password
        }))
      }
    };

    useEffect(() => {

      if(localStorage.getItem('logInCalled') === 'true' && !user.isLoading){
        localStorage.removeItem('logInCalled')
        dispatch(setLoading(false))
        if(user.success){
          toast.success(user.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          localStorage.setItem('accessToken', user?.profile?.token)
          location.pathname = '/'
        }else{
          toast.error(user.message)
        }
      }
      
    }, [user])
  
    return (
        <div className="flex flex-col items-center justify-center min-h-screen px-5 sm:px-0">
        <form onSubmit={handleSubmit} className="bg-[#242B3E] shadow-md rounded px-6 pt-10 pb-10 sm:px-12 sm:pt-[70px] sm:pb-[70px] mb-4 w-full sm:w-[450px] min-h-[400px] flex justify-between flex-col">
          <div className="mb-4">
            <label htmlFor="email" className="block text-white font-bold mb-2">
              Email
            </label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-black  "
            />
          </div>
          <div className="mb-4 mt-4">
            <label htmlFor="password" className="block text-white font-bold mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-black  "
            />
          </div>
          <div className="mb-4 flex justify-end">
            <span><Link to="/password-reset" className='underline'>Forget Password?</Link></span>
          </div>
          <div className="flex items-end justify-between mt-6 flex-col">
            <button
              type="submit"
              className=" bg-[#FFD74A] hover:brightness-125 !text-[#242B3E] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full uppercase"
            >
              Sign In
            </button>
            {/* <span className='mt-4'>You don't have an account? <Link to="/sign-up" className='underline'>Sign up</Link></span> */}
          </div>
          {errorMessage && (
            <div className="mt-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}
        </form>
      </div>
    )
}

export default Login
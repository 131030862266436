import React from 'react';

const Tab = ({ children, active, onClick, svg, childrenStyle, nohover }) => (
  <div
    onClick={onClick}
    className={`${svg ? 'flex  p-3 pl-0 items-center gap-[18px]' : 'manageTabOuterDiv'} `}
  >
    {svg && (
      <svg
        className="rotate-180"
        width="15"
        height="9"
        viewBox="0 0 15 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8569 1.42773L8.56404 6.72063C8.17352 7.11115 7.54035 7.11115 7.14983 6.72063L1.85693 1.42773"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    )}
    <div
      style={childrenStyle}
      className={`${active ? 'activeTab' : nohover ? '' : 'nonActiveTab'} text-secondary flex gap-[16px]`}
    >
      {children}
    </div>
  </div>
);

export default Tab;
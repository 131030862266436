import React from "react";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useFormattedAmount } from "../../../hooks/useFormattedAmount";
import { getColorHex } from "../../../shared/lib/getColorHex";
import { getInitials } from "../../../shared/lib/getInitials";
function PlanUsageDetailsTableBody({ tableData }) {
  const formatFunction = useFormattedAmount();
  return (
    <tbody>
      {tableData &&
        tableData.map((row, index) => {
          const [hexColor, hexWithOpacity] = getColorHex(row.id);
          return (
            <tr key={index} className="rounded-[24px]">
              {Object.keys(row)
                .filter((item) => item !== "id")
                .map((item, index2) => (
                  <td
                    key={index2}
                    className={cn(
                      "text-secondary !text-[#FFFFFF] px-[16px] py-[20px] text-left",
                      {
                        "!px-[10px] w-[45%]": item === "user",
                      }
                    )}
                  >
                    {item === "user" && (
                      <div className="flex items-center gap-4">
                        <div
                          style={{
                            backgroundColor: hexWithOpacity,
                            color: hexColor,
                          }}
                          className="w-[40px] h-[40px] rounded-full flex items-center justify-center"
                        >
                          {getInitials(row[item])}
                        </div>
                        <div className="flex flex-col">
                          <span className="text-secondary !text-[#FFFFFF] !text-left !font-medium inline_text">
                            {row[item]}
                          </span>
                          <span className="text-secondary !text-left inline_text">
                            Work distinction
                          </span>
                        </div>
                      </div>
                    )}
                    {item === "total_cost" && formatFunction(row[item])}
                    {item === "data_used" && (
                      <div>
                        <p
                          className={`text-sm font-medium ${
                            row[item] > 100 ? "text-[#F25757]" : "text-white"
                          } ${row[item] < 50 ? "!text-[#6DEE6D]" : ""} ${
                            row[item] === 50 ? "!text-[#F17B38]" : ""
                          }`}
                        >
                          {row[item]}%
                        </p>
                        <p className="mt-1 text-secondary">268GB</p>
                      </div>
                    )}
                    {item === "minutes_used" && (
                      <div>
                        <p
                          className={`text-sm font-medium ${
                            row[item] > 100 ? "text-[#F25757]" : "text-white"
                          } ${row[item] < 50 ? "!text-[#6DEE6D]" : ""} ${
                            row[item] === 50 ? "!text-[#F17B38]" : ""
                          }`}
                        >
                          {row[item]}%
                        </p>
                        <p className="mt-1 text-secondary">389m</p>
                      </div>
                    )}
                    {item === "roaming_used" && (
                      <div>
                        <p
                          className={`text-sm font-medium ${
                            row[item] > 100 ? "text-[#F25757]" : "text-white"
                          } ${row[item] < 50 ? "!text-[#6DEE6D]" : ""} ${
                            row[item] === 50 ? "!text-[#F17B38]" : ""
                          }`}
                        >
                          {row[item]}%
                        </p>
                        <p className="mt-1 text-secondary">50m</p>
                      </div>
                    )}
                  </td>
                ))}
            </tr>
          );
        })}
      {!tableData && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          {[1, 2, 3, 4, 5].map((el, index) => {
            return (
              <tr key={index}>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </SkeletonTheme>
      )}
    </tbody>
  );
}

export default PlanUsageDetailsTableBody;

const getColorHex = (index = 0) => {
  const colors = [
    "#2284FF",
    "#E9D689",
    "#6BE261",
    "#D6A1F2",
    "#5D61E2",
    "#98EBC4",
    "#7885EB",
  ];
  const hex = `${colors[index % 7]}`;
  const hexAlpha = hex + 27;

  return [hex, hexAlpha];
};

export { getColorHex };

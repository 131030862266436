import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { formatedAmount } from "../../../../utils/helpers";
import {useFormattedAmount} from "../../../../hooks/useFormattedAmount";

function PillCards({ totalTeamCost, averageEmployeeCost }) {
    const formatFunction = useFormattedAmount();
  return (
    <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
      <div className="flex flex-wrap gap-[32px]">
        <div className="flex justify-between items-center gap-[12px] w-full max-w-[100%] h-[100px] sm:max-w-[calc(50%-16px)] p-[24px] rounded-[32px] bg-[#363D50]">
          <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
            {totalTeamCost && (
              <span className="text-primary !text-[18px] block">
                {formatFunction(totalTeamCost)}
              </span>
            )}
            {totalTeamCost === undefined && <Skeleton className="!w-[132px]" />}
            <span className="text-secondary !leading-[21px]">
              Total team cost
            </span>
          </div>
        </div>

        <div className="flex justify-center flex-col w-full max-w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] sm:max-w-[calc(50%-16px)] rounded-[32px] bg-[#363D50]">
          {totalTeamCost && (
            <span className="text-primary !text-[18px] block">
              {formatFunction(averageEmployeeCost)}
            </span>
          )}
          {totalTeamCost === undefined && <Skeleton className="!w-[132px]" />}
          <span className="text-secondary !leading-[21px]">
            Average employee cost
          </span>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default PillCards;

import React, { useEffect, useState } from 'react'
import BasicModal from '../../../../components/models/models';
import ChartCard from './components/chart-card';
import Header from './components/header';
import PillCards from './components/pill-cards';
import ServicesRequestAndFeedbackCards from './components/services-request-and-feedback-card';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { getCompanyUsageReportData, selectCompanyDashboard } from '../../../../redux/slices/companyDashboardSlice';

function UsageCompanyReportModule({ path }) {
  const location = useLocation();
  const dispatch = useDispatch();
	const { id } = useParams()
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [feedbackID, setFeedbackID] = useState()
  const [requestId, setRequestId] = useState()
  const State = useSelector(selectCompanyDashboard);
  const { usagecompanyReportData } = State;
  const queryParams = new URLSearchParams(location.search);
  const dataId = queryParams.get('dataId');  
  
  useEffect(() => {
    dispatch(getCompanyUsageReportData(id, dataId));
  }, [dispatch, dataId, id]);
  return (
    <div className='right_panel relative flex flex-col px-[20px] lg:px-[32px] pt-[16px]'>
			<Header path={path} data={usagecompanyReportData} />
      <div className="card-section">
        <div className='cards_wrap flex flex-col gap-[40px] h-[100vh] lg:h-[680px] pt-[32px] pb-[20px] overflow-y-scroll scrollbar-hide'>
          <PillCards data={usagecompanyReportData} setPopup={setPopup} setState={setState} setRequestId={setRequestId}/>
          <ChartCard />
          <ServicesRequestAndFeedbackCards data={usagecompanyReportData} setFeedbackID={setFeedbackID} setPopup={setPopup} setState={setState} setRequestId={ setRequestId } id={{ id: id, service_emp_id:dataId}} />
        </div>
      </div>

			{setPopup && (
        <BasicModal
          id={{ id: requestId && requestId, userId :usagecompanyReportData?.user?.id}}
          feedBackId={feedbackID}
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          serviceId= { usagecompanyReportData?.service?.id }
          dashboard="companyDahboard"
          feedid={{ id: usagecompanyReportData?.user?.id, feedbackId:feedbackID}}
        />
      )}
    </div>
  )
}

export default UsageCompanyReportModule;
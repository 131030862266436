import React, { useEffect, useState } from "react";
import BasicModal from "../../../../components/models/models";
import Header from "./components/header";
import PillCards from "./components/pill-cards";
import ServicesAndEmployeesCostCards from "./components/services-and-employees-cost-cards";
import SubmittedAndRatedServiceCards from "./components/submitted-and-rated-service-cards";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomeData,
  getManageServicesDataScore,
  selectCompanyDashboard,
} from "../../../../redux/slices/companyDashboardSlice";

function HomeModule({ show, setShow, }) {
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const dispatch = useDispatch();

  const State = useSelector(selectCompanyDashboard);
  const { homeData, manageServicesData } = State;
  // const setupServices = homeData?.setup_services
  const dataScorePercentage = manageServicesData.data_score_percentage
  const upcomingReportMonth = manageServicesData.upcoming_report_month

  useEffect(() => {
    dispatch(getHomeData());
  }, [dispatch]);

  useEffect(()=>{
    dispatch(getManageServicesDataScore())
  },[dispatch]);

  return (
    <div className="right_panel flex flex-col w-[100%] gap-[32px] pt-[20px] !pb-[37px] xl:pt-[32px] xl:max-w-[1099px] 6xl:max-w-[80%] pr-[24px]">
      <Header
        data={homeData}
        show={show}
        setShow={setShow}
        setPopup={setPopup}
        setState={setState}
        dataScorePercentage={dataScorePercentage}
        upcomingReportMonth={upcomingReportMonth}
      />
      <div className="card-section">
        <div className="cards_wrap inner_space flex flex-col gap-[40px] h-[100vh] lg:h-[calc(100vh-135px)] overflow-y-scroll scrollbar-hide home_module">
          <PillCards data={homeData} setPopup={setPopup} setState={setState} />

          <ServicesAndEmployeesCostCards
            data={homeData}
            setPopup={setPopup}
            setState={setState}
          />

          <SubmittedAndRatedServiceCards
            data={homeData}
            setPopup={setPopup}
            setState={setState}
          />
        </div>
      </div>

      {setPopup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          // manageServicesData={manageServicesData}
        />
      )}
    </div>
  );
}

export default HomeModule;

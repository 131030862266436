import React, { useState } from "react";
import Table from "../../Table/table";
import {
  heading,
  tableData,
} from "../../../services/planusageDetailsTableData";

export default function PlanUsageModel({ setPopup, planUsageData }) {
  const [orderType, setOrderType] = useState("asc");
  const [collapse, setCollapse] = useState(true);
  const [data, setData] = useState([...tableData]);

  const transformedData = transformResponse(tableData);

  function transformResponse(response = []) {
    return response?.map((item) => {
      return {
        id: item.id,
        expense: item.expense,
        employee: item.employee,
        cost: item.cost,
        charge_date: item.charge_date,
        inquired_date: item.inquired_date,
        receipt: item.receipt,
      };
    });
  }

  const handlePlanUser = (orderType) => {
    const sort = [...data].sort((a, b) => {
      const serviceA = a.user || "";
      const serviceB = b.user || "";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });

    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  }; 

  const handlePlanTotalCost = (orderType) => {
    const sort = [...data].sort((a, b) => {
      const serviceA = a.total_cost || 0;
      const serviceB = b.total_cost || 0;
      if (orderType === "asc") {
        return serviceB - serviceA;
      } else {
        return serviceA - serviceB;
      }
    });

    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handlePlanDataUsed = (orderType) => {
    const sort = [...data].sort((a, b) => {
      const serviceA = a.data_used || 0;
      const serviceB = b.data_used || 0;
      if (orderType === "asc") {
        return serviceB - serviceA;
      } else {
        return serviceA - serviceB;
      }
    });

    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handlePlanMinutesUsed = (orderType) => {
    const sort = [...data].sort((a, b) => {
      const serviceA = a.minutes_used || 0;
      const serviceB = b.minutes_used || 0;
      if (orderType === "asc") {
        return serviceB - serviceA;
      } else {
        return serviceA - serviceB;
      }
    });

    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handlePlanRoamingUsed = (orderType) => {
    const sort = [...data].sort((a, b) => {
      const serviceA = a.roaming_used || 0;
      const serviceB = b.roaming_used || 0;
      if (orderType === "asc") {
        return serviceB - serviceA;
      } else {
        return serviceA - serviceB;
      }
    });

    setData([...sort]);
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  return (
    <div className="relative">
      <div className="relative overflow-x-hidden transform rounded-[24px] bg-[#363D50] transition-all lg:w-[80vw] 2xl:w-[80vw] 2xl:min-w-[1270px] lg:h-[80vh] 4xl:h-[711px] overflow-y-scroll scrollbar-hide bg-[#363D50] rounded-[16px] flex flex-row">
        <div className="w-full">
          <div className="flex align-center justify-between py-[16px] px-[24px]">
            <div className="flex gap-4 items-center">
              <div>
                <h3 className="font-bold text-left text-[24px]">
                  Plan name usage data(Jan)
                </h3>
                <p className="text-secondary text-left">50 users total</p>
              </div>
            </div>
            <div className="flex h-[40px] justify-end items-center">
              <svg
                className="cursor-pointer"
                onClick={() => {
                  setPopup(false);
                }}
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="px-6">
            <div className="rounded-3xl border border-solid border-[#434A5D] px-8 py-6">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-white">
                  Contract overview
                </p>
                <div
                  className={`cursor-pointer ${collapse ? "" : "rotate-180"}`}
                  onClick={() => {
                    setCollapse(!collapse);
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 14L12 9L7 14"
                      stroke="white"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {collapse && (
                <div className="mt-4 flex items-center gap-[56px]">
                  <div className="my-2">
                    <p className="text-[32px] font-bold text-white">$250.00</p>
                    <p className="text-secondary text-left mt-2">
                      Cost per plan
                    </p>
                  </div>
                  <div className="my-2">
                    <p className="text-[32px] font-bold text-white">128GB</p>
                    <p className="text-secondary text-left mt-2">Data limit</p>
                  </div>
                  <div className="my-2">
                    <p className="text-[32px] font-bold text-white">400</p>
                    <p className="text-secondary text-left mt-2">
                      Minutes limit
                    </p>
                  </div>
                  <div className="my-2">
                    <p className="text-[32px] font-bold text-white">100</p>
                    <p className="text-secondary text-left mt-2">
                      Roaming limit
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="relative mt-1 bg-[#363D50] overflow-y-scroll scrollbar-hide h-[580px]">
            <Table
              headings={heading}
              orderType={orderType}
              tableData={data}
              table={"planUsageDetails"}
              handlePlanUser={handlePlanUser}
              handlePlanTotalCost={handlePlanTotalCost}
              handlePlanDataUsed={handlePlanDataUsed}
              handlePlanMinutesUsed={handlePlanMinutesUsed}
              handlePlanRoamingUsed={handlePlanRoamingUsed}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function ReceiptModel({ setPopup, receiptData }) {
  return (
    <div className="relative">
      <div className="transform rounded-[16px] p-2 bg-[#434A5D] transition-all w-[417px]">
        <div className="flex items-center justify-between">
          <p className="text-secondary">File name</p>
          <div className="flex items-center gap-4">
            <button>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 4.10352C12.5 3.82737 12.2761 3.60352 12 3.60352C11.7239 3.60352 11.5 3.82737 11.5 4.10352L11.5 13.8964L8.35355 10.75C8.15829 10.5547 7.84171 10.5547 7.64645 10.75C7.45118 10.9452 7.45118 11.2618 7.64645 11.4571L11.2929 15.1035C11.6834 15.494 12.3166 15.494 12.7071 15.1035L16.3536 11.4571C16.5488 11.2618 16.5488 10.9452 16.3536 10.75C16.1583 10.5547 15.8417 10.5547 15.6464 10.75L12.5 13.8964V4.10352Z"
                  fill="white"
                />
                <path
                  d="M3.5 17.8965C3.5 19.2772 4.61929 20.3965 6 20.3965H18C19.3807 20.3965 20.5 19.2772 20.5 17.8965V14.8965C20.5 14.6203 20.2761 14.3965 20 14.3965C19.7239 14.3965 19.5 14.6203 19.5 14.8965V17.8965C19.5 18.7249 18.8284 19.3965 18 19.3965L6 19.3965C5.17157 19.3965 4.5 18.7249 4.5 17.8965L4.5 14.8965C4.5 14.6203 4.27614 14.3965 4 14.3965C3.72386 14.3965 3.5 14.6203 3.5 14.8965V17.8965Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                setPopup(false);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.7071 5.29304C18.3166 4.90252 17.6834 4.90252 17.2929 5.29304L12 10.5859L6.70711 5.29304C6.31658 4.90252 5.68342 4.90252 5.29289 5.29304C4.90237 5.68357 4.90237 6.31673 5.29289 6.70726L10.5858 12.0002C11.3668 12.7812 12.6332 12.7812 13.4142 12.0002L18.7071 6.70726C19.0976 6.31673 19.0976 5.68357 18.7071 5.29304Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.29289 18.2929C5.68342 18.6834 6.31658 18.6834 6.70711 18.2929L12 13L17.2929 18.2929C17.6834 18.6834 18.3166 18.6834 18.7071 18.2929C19.0976 17.9024 19.0976 17.2692 18.7071 16.8787L13.4142 11.5858C12.6332 10.8047 11.3668 10.8047 10.5858 11.5858L5.29289 16.8787C4.90237 17.2692 4.90237 17.9024 5.29289 18.2929Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="mt-2.5 max-h-[534px]">
          <img
            className="w-full h-full rounded-xl"
            alt="receipt"
            src="https://s3-alpha-sig.figma.com/img/cbdb/1381/b80cd1e3f690ef75501be6825af503fd?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jZH46SDcbaudZb80HRKJyCJ~vfQqtNVcAENkZPB2TPnPK3CfxP0wA3vnREoeP6ETIVz3QXAi1jwBx9XOID9AyA59pCFU2Emy2GiGoAI7zAzZj5HiJSOOybdmzdyQblYcTlV32mmG5ucbGWFAA3Vf679CDudfQEZPyj4ofF0rbpRKH~Xwa1oJZQYqlzDLMv3jcjp6Jl6OTCKRAEPHZp8knWmZzExCUw9HLmkJA~xw1WVN23WoPmRnzIK~ZK-oJ-uMSrWNk8PfP4c4m-2gbDCpiU6AL2AtlKSvoF94SZIS8LA8guDLyAHM4ue~SiSeXssbs3nM7UcW0MSKTQ3aoQkp0w__"
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { formatedAmount } from "../../../../../utils/helpers";
import {useFormattedAmount} from "../../../../../hooks/useFormattedAmount";

function PillCards({ data }) {
  const formatFunction = useFormattedAmount();
  return (
    <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
      <div className="grid grid-cols-1 md:grid-cols-2 justify-center xl:justify-between items-center gap-[40px]">
        <div className="max-w-[100%] p-[24px] bg-[#363D50] rounded-[32px]">
          <span className="text-primary !text-[18px] block">
            {formatFunction(data.average_service_cost)}
          </span>
          {data.average_service_cost === undefined && <Skeleton className="!w-[132px]" />}
          <span className="text-secondary !leading-[21px] mt-[6px]">
            Average service cost
          </span>
        </div>
        <div className="max-w-[100%] p-[24px] bg-[#363D50] rounded-[32px]">
          <span className="text-primary !text-[18px] block">
            {formatFunction(data.average_employee_cost)}
          </span>
          {data.average_employee_cost === undefined && <Skeleton className="!w-[132px]" />}
          <span className="text-secondary !leading-[21px] mt-[6px]">
            Average employee cost
          </span>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default PillCards;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Pill from "../../Pill/pill";
import { getUserDetails, selectUser } from "../../../redux/slices/userSlice";
import { empSubmissionData } from "../../../redux/slices/companyDashboardSlice";
function SubmissionTableBody({
  tableData,
  model = false,
  show,
  setShow,
  setState,
  setId,
  setPopup,
  empid,
  tablename,
}) {
  const dispatch = useDispatch();
  const { userDetails } = useSelector(selectUser);
  return (
    <tbody>
      {tableData &&
        tableData.map((row, index) => (
          <tr
            key={index}
            className="hover:bg-[#434a5d] cursor-pointer rounded-[24px]"
            onClick={() => {
              if (model) {
                setShow(!show);
                setId({ id: row.id, userId: empid });
                // if (tablename === "company_dashboard") {
                //   dispatch(empSubmissionData(empid, row.id));
                // }
              } else {
                setPopup(true);
                setState(18);
                setId({ id: row.id, userId: userDetails?.user.id });
              }
            }}
          >
            {Object.keys(row)
              .filter((item) => item !== "id")
              .map((item, index2) => (
                <td
                  key={index2}
                  className={cn(
                    "text-secondary !text-[#FFFFFF] py-[30px] px-[16px]",
                    {
                      "!font-medium !text-[16px]": item === "date",
                      "!font-medium text-left": item === "name",
                    }
                  )}
                >
                  {item === "type" && <Pill fill={true}>{row[item]}</Pill>}
                  {item === "category" && (
                    <div className="flex gap-2">
                      {row[item]?.length > 0 ? (
                        row[item].map((value, index) => (
                          <Pill key={index} outline={true}>
                            {value.name}
                          </Pill>
                        ))
                      ) : (
                        <Pill outline={true}>N/A</Pill>
                      )}
                    </div>
                  )}
                  {item === "expensed" && (
                    <span>
                      {row[item] ? (
                        <Pill check={true} greenFill={true} />
                      ) : (
                        <Pill cross={true} redFill={true} />
                      )}
                    </span>
                  )}
                  {item === "website" && (
                    <span>
                      {row[item] === null ? (
                        "-"
                      ) : (
                        <Link to={row[item]}>
                          <Pill link={true} />
                        </Link>
                      )}
                    </span>
                  )}
                  {item !== "type" &&
                    item !== "expensed" &&
                    item !== "website" &&
                    item !== "category" &&
                    row[item]}
                </td>
              ))}
          </tr>
        ))}
      {!tableData && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          {[1, 2, 3, 4, 5].map((el, index) => {
            return (
              <tr key={index}>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </SkeletonTheme>
      )}
    </tbody>
  );
}

export default SubmissionTableBody;

import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ManageAlert from '../components/ManageAlert'
import ManageHeader from '../components/ManageHeader'
import chevronIcon from "../../../../../assets/images/chevronIcon.png";
import uploadIcon from "../../../../../assets/images/ic_download.png";
import Button from "../../../../../components/Buttons/button";
import { FileUploader } from "react-drag-drop-files";
import { getYearlyUsegesServiceData, setUserServiceFalse, updateCustomerServiceUsegesReport, getServiceTemplate, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';

const UsageData = ({service_id}) => {
  const dispatch = useDispatch();
  const [alertClose, setAlertClose] = useState(false)
  const [year, setYear] = useState(2024)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadingIndex, setUploadingIndex] = useState("")
  const State  = useSelector(selectCompanyDashboard)
  const {YearlyUsegeServiceData, template, UserServiceData, manageServicesData} = State
  const [selectedDate, setSelectedDate] = useState(null);
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const reportDay = manageServicesData.monthly_report_day

  const calculateRemainingDays = (reportDay, currentDay) => {
    let remainingDays = reportDay - currentDay;

    if (remainingDays < 0) {
      const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
      remainingDays = (daysInCurrentMonth - currentDay) + Number(reportDay);
    }

    return remainingDays;
  };

  const remainingDays = calculateRemainingDays(reportDay, currentDay);

  const formatDate = (date) => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const formattedParts = date.toLocaleDateString('en-US', options).split(', ');
    return formattedParts.join(' ');
  };

  useEffect(()=>{
    dispatch(getServiceTemplate(service_id))
  },[])

  const handleUpload = () => {
  }

  const handleNextIcon = () => {
    setYear((previous) => previous+1 )
  }

  const handlePreviousIcon = () => {
    setYear((previous) => previous-1 )
  }

  const handleFileUpload = (e, index, month) => {
    dispatch(setUserServiceFalse());
    const files = e.target.files;
    if(files){
      setIsUploading(true)
      setUploadingIndex(index)
      const currentDate = new Date().getDate();
      const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'short' });
      const date = new Date(`${currentDay} ${month} ${currentDate} ${year}`);
      const formattedDate = formatDate(date);
      // setSelectedDate(formattedDate);
      const formData = new FormData();
      formData.append("billing_period", formattedDate);
      formData.append("file", files[0]);
      dispatch(updateCustomerServiceUsegesReport(service_id, formData));
      setSelectedDate(formattedDate);
    }
  }

  // useEffect(()=>{
  //  dispatch(setUserServiceFalse());
  // },[])

  useEffect(()=>{
    dispatch(getYearlyUsegesServiceData(service_id,year))
  },[year,service_id])

  useEffect(()=>{
    if (UserServiceData){
      setUploadingIndex()
      dispatch(getYearlyUsegesServiceData(service_id,year))
    }
  },[UserServiceData])


  const getDaysRemainingInMonth = () => {
    const today = new Date();
    const currentDay = today.getDate();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
    const remainingDays = lastDayOfMonth - currentDay;
    return remainingDays;
  }

  return (
    <div className="showDatePanel">
      <div className={`${!alertClose && "manage-divider px-[16px] py-[12px] h-[88px] flex items-center"}`}>
        <ManageAlert close={alertClose} setClose={setAlertClose} />
      </div>
      <div className="panelContent">
        <div className={`py-[16px] px-thirty_two`}>
          <div>
            <div className="flex items-center justify-between">
              <div>
                <div className=" flex items-center gap-[20px] border-[1px] rounded-[20px] border-[#596073] hover:border-[#A5AEBC] focus:border-[#EAC400] hover:color-[#FFE766] focus:color-[#EAC400] pt-[12px] pl-[20px] pb-[12px] pr-[20px]">
                  <button className="rotate-90" onClick={handlePreviousIcon}>
                    <img src={chevronIcon} alt="chevronIcon" />
                  </button>
                  { year }
                  <button className="rotate-[270deg]" onClick={handleNextIcon} disabled={ year == 2024 ? true : false}>
                    <img src={chevronIcon} alt="chevronIcon" />
                  </button>
                </div>
              </div>
              <div>
                <div className="downloadLink cursor-pointer">
                  <img src={uploadIcon} alt="icon" className="iconDown" />
                  {/*<span> Download template </span>*/}
                  <a href={template.filePath}>
                    <span className="text-secondary downloadLink ">
                      {template.download_label}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="detailsSection scrollbar-hide">
          { YearlyUsegeServiceData?.monthly_result?.map((yearlyResult, index, yearArray)=>(
            <>
              { index === uploadingIndex ? 
                <div className="py-[0px] px-thirty_two">
                <div className="card-animate w-[100%] py-[16px] px-[10px] border-[#41485C] hover:rounded-[20px]">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-[14px]">
                      <div className="w-6 h-6 border-4 border-[#FFD74A] border-t-transparent border-solid rounded-full animate-spin"></div>
                      <div className="flex flex-col">
                        <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                          { yearlyResult?.month }
                        </span>
                        <span className="text-secondary !leading-[20px] !text-left">
                         uploading...
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center gap-[14px]">
                      <div className="flex items-center gap-1">
                        <span className="text-secondary !leading-[20px] !text-left">
                          {getDaysRemainingInMonth()} days until report generation
                        </span>
                      </div>
                        <div>
                          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="2" y="2" width="44" height="44" rx="16" fill="#363D50"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 32.3965C16.6193 32.3965 15.5 31.2772 15.5 29.8965L15.5 26.8965C15.5 26.6203 15.7239 26.3965 16 26.3965C16.2761 26.3965 16.5 26.6203 16.5 26.8965L16.5 29.8965C16.5 30.7249 17.1716 31.3965 18 31.3965L30 31.3965C30.8284 31.3965 31.5 30.7249 31.5 29.8965L31.5 26.8965C31.5 26.6203 31.7239 26.3965 32 26.3965C32.2761 26.3965 32.5 26.6203 32.5 26.8965L32.5 29.8965C32.5 31.2772 31.3807 32.3965 30 32.3965L18 32.3965ZM24 27.3965C23.7239 27.3965 23.5 27.1726 23.5 26.8965L23.5 17.1036L20.3536 20.25C20.1583 20.4453 19.8417 20.4453 19.6464 20.25C19.4512 20.0548 19.4512 19.7382 19.6464 19.5429L23.2929 15.8965C23.6834 15.506 24.3166 15.506 24.7071 15.8965L28.3536 19.5429C28.5488 19.7382 28.5488 20.0548 28.3536 20.25C28.1583 20.4453 27.8417 20.4453 27.6464 20.25L24.5 17.1036L24.5 26.8965C24.5 27.1726 24.2761 27.3965 24 27.3965Z" fill="#FFD74A"/>
                          </svg>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              : 
                <div className="py-[0px] px-thirty_two" key={index}>
                <div className="card-animate w-[100%] py-[16px] px-[10px] border-[#41485C] hover:rounded-[20px]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-[14px]">
                   { yearlyResult.present ?
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="8" fill="#6BE261" fill-opacity="0.08"/>
                        <path d="M8.33317 11.3333L13.2498 6.41667C13.4026 6.26389 13.5971 6.1875 13.8332 6.1875C14.0693 6.1875 14.2637 6.26389 14.4165 6.41667C14.5693 6.56944 14.6457 6.76389 14.6457 7C14.6457 7.23611 14.5693 7.43056 14.4165 7.58333L8.9165 13.0833C8.74984 13.25 8.55539 13.3333 8.33317 13.3333C8.11095 13.3333 7.9165 13.25 7.74984 13.0833L5.58317 10.9167C5.43039 10.7639 5.354 10.5694 5.354 10.3333C5.354 10.0972 5.43039 9.90278 5.58317 9.75C5.73595 9.59722 5.93039 9.52083 6.1665 9.52083C6.40261 9.52083 6.59706 9.59722 6.74984 9.75L8.33317 11.3333Z" fill="#6BE261"/>
                      </svg>
                   :
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="20" height="20" rx="8" fill="#FF5555" fill-opacity="0.08"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.07916 5.96721C6.79109 5.6776 6.32403 5.6776 6.03596 5.96721C5.74789 6.25682 5.74789 6.72637 6.03596 7.01598L8.9694 9.96509L5.96605 12.9845C5.67798 13.2741 5.67798 13.7436 5.96605 14.0332C6.25413 14.3229 6.72118 14.3229 7.00926 14.0332L10.0126 11.0139L12.9906 14.0078C13.2787 14.2974 13.7458 14.2974 14.0338 14.0078C14.3219 13.7182 14.3219 13.2486 14.0338 12.959L11.0558 9.96509L13.9639 7.04143C14.252 6.75182 14.252 6.28226 13.9639 5.99265C13.6759 5.70304 13.2088 5.70304 12.9207 5.99265L10.0126 8.91631L7.07916 5.96721Z" fill="#ED4650"/>
                    </svg>
                  }

                    <div className="flex flex-col">
                      <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                        { yearlyResult?.month }
                      </span>
                      <span className="text-secondary !leading-[20px] !text-left">
                        { yearlyResult.present ? "Data uploaded" : "Missing Data" }
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-[18px]">
                    <div className="flex items-center gap-1">
                      {index === 0 && !yearlyResult.present && reportDay && (
                        <>
                          <span className="text-secondary !leading-[20px] !text-left">
                           {`${remainingDays} days until report generation`}
                          </span>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99984 14.166C10.2359 14.166 10.434 14.086 10.594 13.926C10.754 13.766 10.8337 13.5682 10.8332 13.3327C10.8326 13.0971 10.7526 12.8993 10.5932 12.7393C10.4337 12.5793 10.2359 12.4993 9.99984 12.4993C9.76373 12.4993 9.56595 12.5793 9.4065 12.7393C9.24706 12.8993 9.16706 13.0971 9.1665 13.3327C9.16595 13.5682 9.24595 13.7663 9.4065 13.9268C9.56706 14.0874 9.76484 14.1671 9.99984 14.166ZM9.99984 10.8327C10.2359 10.8327 10.434 10.7527 10.594 10.5927C10.754 10.4327 10.8337 10.2349 10.8332 9.99935V6.66602C10.8332 6.4299 10.7532 6.23213 10.5932 6.07268C10.4332 5.91324 10.2354 5.83324 9.99984 5.83268C9.76428 5.83213 9.5665 5.91213 9.4065 6.07268C9.2465 6.23324 9.1665 6.43102 9.1665 6.66602V9.99935C9.1665 10.2355 9.2465 10.4335 9.4065 10.5935C9.5665 10.7535 9.76428 10.8332 9.99984 10.8327ZM9.99984 18.3327C8.84706 18.3327 7.76373 18.1138 6.74984 17.676C5.73595 17.2382 4.854 16.6446 4.10401 15.8952C3.354 15.1457 2.76039 14.2638 2.32317 13.2493C1.88595 12.2349 1.66706 11.1516 1.6665 9.99935C1.66595 8.84713 1.88484 7.76379 2.32317 6.74935C2.76151 5.73491 3.35512 4.85296 4.10401 4.10352C4.85289 3.35407 5.73484 2.76046 6.74984 2.32268C7.76484 1.8849 8.84817 1.66602 9.99984 1.66602C11.1515 1.66602 12.2348 1.8849 13.2498 2.32268C14.2648 2.76046 15.1468 3.35407 15.8957 4.10352C16.6446 4.85296 17.2384 5.73491 17.6773 6.74935C18.1162 7.76379 18.3348 8.84713 18.3332 9.99935C18.3315 11.1516 18.1126 12.2349 17.6765 13.2493C17.2404 14.2638 16.6468 15.1457 15.8957 15.8952C15.1446 16.6446 14.2626 17.2385 13.2498 17.6768C12.2371 18.1152 11.1537 18.3338 9.99984 18.3327Z" fill="#ED4650"/>
                          </svg>
                        </>
                      )}
                    </div>
                    {  !yearlyResult.present ?
                      <Button
                        // onClick={handleUpload}
                        outlined={true}
                        hoverColor={true}
                        secondary={false}
                        secondaryHoverOutlined={true}
                        fill={true}
                      >
                        <label className="flex cursor-pointer items-center gap-1">
                          <input type="file"
                            hidden
                            id="xlsxfile"
                            name="files"
                            accept=".xlsx, .csv"
                            onChange={(e)=>handleFileUpload(e,index,yearlyResult?.month) }
                          />
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 20.3965C4.61929 20.3965 3.5 19.2772 3.5 17.8965L3.5 14.8965C3.5 14.6203 3.72386 14.3965 4 14.3965C4.27614 14.3965 4.5 14.6203 4.5 14.8965L4.5 17.8965C4.5 18.7249 5.17157 19.3965 6 19.3965L18 19.3965C18.8284 19.3965 19.5 18.7249 19.5 17.8965L19.5 14.8965C19.5 14.6203 19.7239 14.3965 20 14.3965C20.2761 14.3965 20.5 14.6203 20.5 14.8965L20.5 17.8965C20.5 19.2772 19.3807 20.3965 18 20.3965L6 20.3965ZM12 15.3965C11.7239 15.3965 11.5 15.1726 11.5 14.8965L11.5 5.10359L8.35355 8.25004C8.15829 8.4453 7.84171 8.4453 7.64645 8.25004C7.45118 8.05478 7.45118 7.73819 7.64645 7.54293L11.2929 3.89648C11.6834 3.50596 12.3166 3.50596 12.7071 3.89648L16.3536 7.54293C16.5488 7.73819 16.5488 8.05477 16.3536 8.25004C16.1583 8.4453 15.8417 8.4453 15.6464 8.25004L12.5 5.10359L12.5 14.8965C12.5 15.1726 12.2761 15.3965 12 15.3965Z" fill="#000000"/>
                          </svg>
                          Upload
                        </label>
                      </Button>
                      :
                      <div className="flex items-center gap-[14px] cursor-pointer">
                        <label className="flex cursor-pointer items-center gap-1">
                          <input type="file"
                            hidden
                            id="xlsxfile"
                            name="files"
                            accept=".xlsx, .csv"
                            onChange={(e)=>handleFileUpload(e,index,yearlyResult?.month) }
                          />
                          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="2" y="2" width="44" height="44" rx="16" fill="#363D50"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 32.3965C16.6193 32.3965 15.5 31.2772 15.5 29.8965L15.5 26.8965C15.5 26.6203 15.7239 26.3965 16 26.3965C16.2761 26.3965 16.5 26.6203 16.5 26.8965L16.5 29.8965C16.5 30.7249 17.1716 31.3965 18 31.3965L30 31.3965C30.8284 31.3965 31.5 30.7249 31.5 29.8965L31.5 26.8965C31.5 26.6203 31.7239 26.3965 32 26.3965C32.2761 26.3965 32.5 26.6203 32.5 26.8965L32.5 29.8965C32.5 31.2772 31.3807 32.3965 30 32.3965L18 32.3965ZM24 27.3965C23.7239 27.3965 23.5 27.1726 23.5 26.8965L23.5 17.1036L20.3536 20.25C20.1583 20.4453 19.8417 20.4453 19.6464 20.25C19.4512 20.0548 19.4512 19.7382 19.6464 19.5429L23.2929 15.8965C23.6834 15.506 24.3166 15.506 24.7071 15.8965L28.3536 19.5429C28.5488 19.7382 28.5488 20.0548 28.3536 20.25C28.1583 20.4453 27.8417 20.4453 27.6464 20.25L24.5 17.1036L24.5 26.8965C24.5 27.1726 24.2761 27.3965 24 27.3965Z" fill="#FFD74A"/>
                          </svg>
                        </label>
                      </div>
                    }
                  </div>
                </div>
                </div>
                </div>
              }
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

export default UsageData
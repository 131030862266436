import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { setEmptyEmployeeData } from "../../../redux/slices/companyDashboardSlice";
import { getInitials } from "../../../shared/lib/getInitials";
import { getColorHex } from "../../../shared/lib/getColorHex";

function EmployeeTableBody({
  tableData,
  setManagerId,
  show,
  setShow,
  model = false,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleEmptyEmployeeData = () => {
    dispatch(setEmptyEmployeeData());
  };

  return (
    <tbody>
      {tableData &&
        tableData?.map((row, index) => {
          const [hexColor, hexWithOpacity] = getColorHex(row.id);

          return (
            <tr
              key={index}
              className="hover:bg-[#363D50] hover:brightness-110 cursor-pointer text-left"
            >
              {Object.keys(row)
                .filter((item) => item !== "id" && item !== "also_manager")
                .map((item, index2) => (
                  <td
                    key={index2}
                    className="text-secondary !text-[#fff] py-[24px] px-[15px] !font-medium"
                    onClick={() => {
                      item !== "more_action"
                        ? navigate(
                            `/company_dashboard/employees/employee_report/${row.id}`
                          )
                        : setManagerId(row.id);
                      handleEmptyEmployeeData();
                    }}
                  >
                    {item === "service" && (
                      <span className="flex gap-[4px] items-center !font-medium">
                        {" "}
                        {row[item]}
                      </span>
                    )}
                    {item === "employee" && (
                      <div className="flex flex-col md:flex-row items-center sm:items-start max-xs:items-start gap-[10px]">
                        <div
                          style={{
                            backgroundColor: hexWithOpacity,
                            color: hexColor,
                          }}
                          className="w-[40px] h-[40px] rounded-full flex items-center justify-center"
                        >
                          {getInitials(row[item]["name"])}
                        </div>
                        <div className="flex flex-col">
                          <span className="text-secondary !text-[#FFFFFF] !text-left !font-medium inline_text">
                            {row[item]["name"]}
                          </span>
                          <span className="text-secondary !text-left inline_text">
                            {row[item]["category"]}
                          </span>
                        </div>
                      </div>
                    )}
                    {item === "monthly_cost" && (
                      <span className="flex gap-[4px] items-center !font-medium">
                        {" "}
                        {row[item]}
                      </span>
                    )}

                    {item === "more_action" && row.also_manager && (
                      <Link
                        to={`/company_dashboard/employees/${row.id}`}
                        className="custom_btn inline_text"
                      >
                        See Team Details
                      </Link>
                    )}

                    {item !== "service" &&
                      item !== "monthly_cost" &&
                      item !== "employee" &&
                      item !== "more_action" &&
                      row[item]}
                  </td>
                ))}
            </tr>
          );
        })}
      {!tableData && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          {[1, 2, 3, 4, 5].map((el, index) => {
            return (
              <tr key={index}>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </SkeletonTheme>
      )}
    </tbody>
  );
}

export default EmployeeTableBody;

import React from 'react'
import UsageData from './manage-tab-screens/UsageData'
import ContractData from './manage-tab-screens/ContractData'
import Integration from './manage-tab-screens/Integration'

function Manage({Tab, id}) {
  return (
    <div style={{ 
      position: 'absolute', 
      top: '16%', 
      left: '32%', 
  }}>
      {Tab === 1 && <UsageData service_id={id}/>}
      {Tab === 2 && <ContractData service_id={id}/>}
      {Tab === 3 && <Integration />}
    </div>
  )
}

export default Manage
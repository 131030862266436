import React, { useState, useEffect } from "react";
import BasicModal from "../../../../components/models/models";
import Header from "../../../../components/Headers/Header";
import {
  heading,
  tableData,
} from "../../../../services/serviceSubmissionTableData";
import Table from "../../../../components/Table/table";
import {
  updatedSubmission,
  getCompanySubmissionData,
  selectCompanyDashboard,
} from "../../../../redux/slices/companyDashboardSlice";
import { useDispatch, useSelector } from "react-redux";

const initialState = {
  monthly_cost: true,
  rating: false,
  contract_end: false,
  user_count: false,
  user_cost_average: false,
  upDown: false,
};

function SubmissionModule({ show, setShow }) {
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [Submission, setSubmission] = useState(tableData);
  const [orderType, setOrderType] = useState("asc");
  const [btns, setBtns] = useState(initialState);
  const [active, setActive] = useState("monthly_cost");

  const State = useSelector(selectCompanyDashboard);
  const { submitted_services } = State.SubmissionsListData;
  const transformedData = transformResponse(
    submitted_services && submitted_services
  );

  useEffect(() => {
    dispatch(getCompanySubmissionData());
  }, []);

  function transformResponse(response) {
    return response;
  }

  const handleServiceSubName = (orderType, set) => {
    setBtns({
      ...btns,
      service_name: true,
      [active !== "service_name" && active]: false,
    });
    setActive("service_name");
    const sort = [...submitted_services].sort((a, b) => {
      const serviceA = a.service_name || "";
      const serviceB = b.service_name || "";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });
    dispatch(updatedSubmission(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleServiceCategory = (orderType, set) => {
    setBtns({
      ...btns,
      categories: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "categories" && active]: false,
    });
    setActive("categories");
    const sort = [...submitted_services].sort((a, b) => {
      const serviceA = a.categories.length > 0 ? a.categories[0] : "";
      const serviceB = b.categories.length > 0 ? b.categories[0] : "";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });
    dispatch(updatedSubmission(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleTotalSubmission = (orderType, set) => {
    setBtns({
      ...btns,
      total_subm_type: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "total_subm_type" && active]: false,
    });
    setActive("total_subm_type");
    let sort = null;
    orderType === "asc"
      ? (sort = [...submitted_services].sort(
          (a, b) => a.total_subm_type - b.total_subm_type
        ))
      : (sort = [...submitted_services].sort(
          (a, b) => b.total_subm_type - a.total_subm_type
        ));
    dispatch(updatedSubmission(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  const handleMissingSubmission = (orderType, set) => {
    setBtns({
      ...btns,
      missing_count: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "missing_count" && active]: false,
    });
    setActive("missing_count");
    let sort = null;
    orderType === "asc"
      ? (sort = [...submitted_services].sort(
          (a, b) => a.missing_count - b.missing_count
        ))
      : (sort = [...submitted_services].sort(
          (a, b) => b.missing_count - a.missing_count
        ));
    dispatch(updatedSubmission(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  const handleSuggested = (orderType, set) => {
    setBtns({
      ...btns,
      suggested_count: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "suggested_count" && active]: false,
    });
    setActive("suggested_count");
    let sort = null;
    orderType === "asc"
      ? (sort = [...submitted_services].sort(
          (a, b) => a.suggested_count - b.suggested_count
        ))
      : (sort = [...submitted_services].sort(
          (a, b) => b.suggested_count - a.suggested_count
        ));
    dispatch(updatedSubmission(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  const handleExpensed = (orderType, set) => {
    setBtns({
      ...btns,
      expensed_count: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "expensed_count" && active]: false,
    });
    setActive("expensed_count");
    let sort = null;
    orderType === "asc"
      ? (sort = [...submitted_services].sort(
          (a, b) => a.expensed_count - b.expensed_count
        ))
      : (sort = [...submitted_services].sort(
          (a, b) => b.expensed_count - a.expensed_count
        ));
    dispatch(updatedSubmission(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  const handleIsService = (orderType, set) => {
    setBtns({
      ...btns,
      service_setup: true,
      [active !== "service_setup" && active]: false,
    });
    setActive("service_setup");

    if (submitted_services) {
      const sort = [...submitted_services].sort((a, b) => {
        const isServiceSetupA = a.service_setup === "Yes" ? 1 : 0;
        const isServiceSetupB = b.service_setup === "Yes" ? 1 : 0;
        return orderType === "asc"
          ? isServiceSetupA - isServiceSetupB
          : isServiceSetupB - isServiceSetupA;
      });
      dispatch(updatedSubmission(sort));
    }

    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  return (
    <div className="right_panel flex flex-col w-[100%] gap-[24px] pt-[20px] xl:pt-[32px] xl:max-w-[1099px] 6xl:max-w-[80%] pr-[24px]">
      <Header
        title={"Submitted Services"}
        subHeading={
          "Non-contracted services your employees are using or suggesting"
        }
        show={show}
        setShow={setShow}
        btns={false}
      />

      <div className="card-section">
        <div className="cards_wrap bottom_space bg-[#363D50] w-[100%] rounded-[24px] overflow-y-scroll scrollbar-hide max-h-[470px] md:max-h-[670px]">
          <Table
            headings={heading}
            tableData={transformedData}
            table={"serviceSubmission"}
            orderType={orderType}
            handleServiceSubName={handleServiceSubName}
            handleServiceCategory={handleServiceCategory}
            handleTotalSubmission={handleTotalSubmission}
            handleMissingSubmission={handleMissingSubmission}
            handleSuggested={handleSuggested}
            handleExpensed={handleExpensed}
            handleIsService={handleIsService}
          />
        </div>
      </div>

      {setPopup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
        />
      )}
    </div>
  );
}

export default SubmissionModule;

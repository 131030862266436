import React, { useState,useEffect } from "react";
import Tab from "../../manage-data/manage-tab/Tab";
import Manage from "../../manage-data/manage";
import { useDispatch, useSelector } from "react-redux";
import { setUserServiceFalse, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';

const ManageData = ({id}) => {
  const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(1);

  useEffect(()=>{
   dispatch(setUserServiceFalse());
  },[])

  return (
  	<div className="tab_section">
	    <div className='manage-service-wrapper flex flex-col gap-[24px] pt-[24px]'>
	      <div className="text-primary !text-[24px] !leading-[24px] text-left px-[32px]">Manage service data</div>
	    </div>
	    <div className="line-head rounded-[24px] bg-[#363D50]">
		    <div
          className={`animation pl-[16px] pr-[0px]  manage_tab`}
          >
            <div className="w-full bg-[#363D50] flex" >
              <Tab
                childrenStyle={{marginLeft: '20px',fontSize: '14px', fontWeight: 700, color: '#A5AEBC', letterSpacing: '0.02em', lineHeight: '21px'}}
                active={tab === 1}
                onClick={()=> setTab(1)}
              >
                User usage data
              </Tab>
              <Tab
                childrenStyle={{marginLeft: '20px',fontSize: '14px', fontWeight: 700, color: '#A5AEBC', letterSpacing: '0.02em', lineHeight: '21px'}}
                active={tab === 2}
                onClick={()=> setTab(2)}
              >
                Contract data
              </Tab>
              <Tab
                childrenStyle={{marginLeft: '20px',fontSize: '14px', fontWeight: 700, color: '#A5AEBC', letterSpacing: '0.02em', lineHeight: '21px'}}
                active={tab === 3}
                onClick={()=> setTab(3)}
              >
                Integrations
              </Tab>
            </div>
          </div>
          <div className="manage_inner_right_model_details">
            <Manage Tab={tab} id={id} />
          </div>
	    </div>
	  </div>
  )
}

export default ManageData
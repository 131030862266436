import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { selectUser, setLoading, signUpRequest } from '../../redux/slices/userSlice';
import {toast} from 'react-toastify';
import SuccessIcon from "../../assets/icons/success-check.svg";
import { useLocation } from 'react-router-dom'
function SignUp() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation()

  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  // const handleUsernameChange = (event) => {
  //   setUsername(event.target.value);
  // };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // if (username === '') {
    //   setErrorMessage('* Username is required');
    // } else
    if (email === '') {
      setErrorMessage('* Email is required');
    } else if (password === '') {
      setErrorMessage('Password is required');
    } else if (password.length < 6) {
      setErrorMessage('Password should be more then 6 characters');
    } else if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
    } else {
      // Perform sign-up logic here
      dispatch(setLoading(true))
      localStorage.setItem('signUpCalled', 'true')
      dispatch(signUpRequest({
        email: email,
        password: password
      }))
    }
  };

  useEffect(() => {

    if(localStorage.getItem('signUpCalled') === 'true' && !user.isLoading){
      localStorage.removeItem('signUpCalled')
      dispatch(setLoading(false))
      if(user.success){
        toast.success(user.message, {
          icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
        });
        localStorage.setItem('accessToken', user?.profile?.token)
        location.pathname = '/'
      }else{
        toast.error(user.message)
      }
    }
    
  }, [user])
  

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-5 sm:px-0">
      <form onSubmit={handleSubmit} className="bg-[#242B3E] shadow-md rounded px-6 pt-10 pb-10 sm:px-12 sm:pt-[70px] sm:pb-[70px] mb-4 w-full sm:w-[450px] min-h-[400px] flex justify-between flex-col">
        {/* <div className="mb-4">
          <label htmlFor="username" className="block text-white font-bold mb-2">
            Username
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div> */}
        <div className="mb-4 mt-4">
          <label htmlFor="email" className="block text-white font-bold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4 mt-4">
          <label htmlFor="password" className="block text-white font-bold mb-2">
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4 mt-4">
          <label htmlFor="confirm-password" className="block text-white font-bold mb-2">
            Confirm Password
          </label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-end justify-between mt-6 flex-col">
          <button
            type="submit"
            disabled={user?.isLoading}
            className="bg-[#FFD74A] hover:brightness-125 !text-[#242B3E] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full uppercase "
            >
              Sign Up
            </button>
            <span className='mt-4'>Already have an account? <Link to="/login" className='underline'>Sign in</Link></span>
          </div>
          {errorMessage && (
            <div className="mt-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}
        </form>
      </div>
    )
}

export default SignUp
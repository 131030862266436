import { heading } from "../../../services/submissionTableData";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import BasicModal from "../../../components/models/models";
import Table from "../../../components/Table/table";
import Header from "../../../components/Headers/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubmissionsRequest,
  selectPersonalDashboard,
  updateSubmissionSortList,
} from "../../../redux/slices/personalDashboardSlice";
import { selectUser } from "../../../redux/slices/userSlice";
import { formatDate } from "../feedback-module/feedbackModule";

const initialState = {
  service_name: false,
  type: false,
  categories: false,
  is_expensed: false,
  website_url: false,
  submitted_on: false,
  // upDown: false,
};

function SubmissionModule({ show, setShow }) {
  const location = useLocation();
  const { openPopup } = location?.state || {};
  const [popup, setPopup] = useState(false);
  const [btns, setBtns] = useState(initialState);
  const [active, setActive] = useState("service_name");
  const [orderType, setOrderType] = useState("asc");
  const [state, setState] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const State = useSelector(selectPersonalDashboard);
  const { userDetails } = useSelector(selectUser);
  const { submissionsData } = State;
  const transformedData = transformResponse(submissionsData.submissions);

  useEffect(() => {
    dispatch(getSubmissionsRequest());
    setIsLoader(true);
  }, [openPopup]);

  useEffect(() => {
    const subm_id = submissionsData?.submissions?.[0]?.id;
    if (openPopup && isLoader) {
      setPopup(true);
      setState(18);
      setId({ id: subm_id, userId: userDetails?.user.id });
    } else {
      setPopup(false);
    }
  }, [openPopup, isLoader, submissionsData]);

  function transformResponse(response) {
    if (response === undefined) {
      return undefined;
    } else {
      return response.map((item) => {
        return {
          id: item.id,
          name: item.service_name,
          type: item.type,
          category: item.categories,
          expensed: item.is_expensed,
          website: item.website_url,
          date: item.submitted_on,
        };
      });
    }
  }

  const handleServicename = (orderType, set) => {
    setBtns({
      ...btns,
      service_name: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "service_name" && active]: false,
    });
    setActive("service_name");
    const sort = [...submissionsData.submissions].sort((a, b) => {
      const serviceA = a.service_name || "";
      const serviceB = b.service_name || "";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });
    dispatch(updateSubmissionSortList(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleServicetype = (orderType, set) => {
    setBtns({
      ...btns,
      type: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "type" && active]: false,
    });
    setActive("type");
    const sort = [...submissionsData.submissions].sort((a, b) => {
      const serviceA = a.type || "";
      const serviceB = b.type || "";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });
    dispatch(updateSubmissionSortList(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleServicecategory = (orderType, set) => {
    setBtns({
      ...btns,
      categories: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "categories" && active]: false,
    });
    setActive("categories");
    const sort = [...submissionsData.submissions].sort((a, b) => {
      const serviceA = a.categories.length > 0 ? a.categories[0].name : "";
      const serviceB = b.categories.length > 0 ? b.categories[0].name : "";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });
    dispatch(updateSubmissionSortList(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleServiceexpensed = (orderType, set) => {
    setBtns({
      ...btns,
      is_expensed: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "is_expensed" && active]: false,
    });
    setActive("is_expensed");
    const sort = [...submissionsData.submissions].sort((a, b) => {
      const serviceA = a.is_expensed ? "true" : "false";
      const serviceB = b.is_expensed ? "true" : "false";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });
    dispatch(updateSubmissionSortList(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const handleServicewebsite = (orderType, set) => {
    setBtns({
      ...btns,
      website_url: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "website_url" && active]: false,
    });
    setActive("website_url");
    const sort = [...submissionsData.submissions].sort((a, b) => {
      const serviceA = a.website_url || "";
      const serviceB = b.website_url || "";
      if (orderType === "asc") {
        return serviceB.localeCompare(serviceA);
      } else {
        return serviceA.localeCompare(serviceB);
      }
    });
    dispatch(updateSubmissionSortList(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  };

  const handleServicesubmitdate = (orderType, set) => {
    setBtns({
      ...btns,
      submitted_on: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "submitted_on" && active]: false,
    });
    setActive("submitted_on");

    const sort = [...submissionsData.submissions].sort((a, b) => {
      const dateA = parseDate(a.submitted_on);
      const dateB = parseDate(b.submitted_on);

      if (orderType === "asc") {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    });

    dispatch(updateSubmissionSortList(sort));
    setOrderType(orderType === "asc" ? "desc" : "asc");
  };

  return (
    <div className="right_panel flex flex-col w-[100%] gap-[42px] pt-[20px] xl:pt-[32px] xl:max-w-[1099px] 6xl:max-w-[80%] pr-[24px]">
      <Header
        title={"Your Service Submissions"}
        subHeading={
          "New or missing services you’ve suggested or reported to your company."
        }
        show={show}
        setShow={setShow}
        btns={false}
      />
      <div className="card-section">
        <div className="cards_wrap bottom_space bg-[#363D50] w-[100%] rounded-[24px] overflow-y-scroll scrollbar-hide max-h-[470px] md:max-h-[670px]">
          <Table
            headings={heading}
            tableData={transformedData}
            setState={setState}
            setId={setId}
            setPopup={setPopup}
            table={"submission"}
            handleServicename={handleServicename}
            handleServicetype={handleServicetype}
            handleServicecategory={handleServicecategory}
            handleServiceexpensed={handleServiceexpensed}
            handleServicewebsite={handleServicewebsite}
            handleServicesubmitdate={handleServicesubmitdate}
            orderType={orderType}
          />
        </div>
      </div>

      {setPopup && (
        <BasicModal
          state={state}
          id={id}
          setId={setId}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          tablename="personaldashborad"
        />
      )}
    </div>
  );
}

export default SubmissionModule;

import React from "react";
import Pill from "../../Pill/pill";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import BasicModal from "../../models/models";
import { useState } from "react";
import { getSubmissionsData } from "../../../redux/slices/companyDashboardSlice";

function EmployeeInfo({ employee }) {
  return (
    <div className="flex flex-col md:flex-row items-center sm:items-start max-xs:items-start gap-[10px]">
      <img
        className="w-[40px] h-[40px]"
        src={employee.image}
        alt="service-img"
      />
      <div className="flex flex-col">
        <span className="text-secondary !text-[#FFFFFF] !text-left">
          {employee.name}
        </span>
        <span className="text-secondary !text-left">{employee.category}</span>
      </div>
    </div>
  );
}

function TableCell({ item, value, className }) {
  if (item === "service_name" || item === "monthly_cost") {
    return (
      <span className={`flex gap-[4px] items-center ${className}`}>
        {value}
      </span>
    );
  }

  if (item === "categories") {
    return (
      <div className={`flex gap-[10px] ${className}`}>
        {value.map((category, index) => {
          return (
            <Pill key={index} outline={true}>
              {category}
            </Pill>
          );
        })}
      </div>
    );
  }

  return value;
}

function ServiceSubmissionTableBody({
  tableData,
  model = false,
  show,
  setShow,
}) {
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [submissionsData, setSubmissionsData] = useState({});
  const dispatch = useDispatch();

  const handleInput = (id) => {
    dispatch(getSubmissionsData(id));
  };

  return (
    <>
      <tbody>
        {tableData &&
          tableData.map((row, index) => (
            <tr
              key={index}
              className="hover:bg-[#363D50] hover:brightness-110 cursor-pointer"
              onClick={() => {
                setState(23);
                setSubmissionsData({
                  service_name: row.service_name,
                  submissions: row.submissions,
                });
                setPopup(true);
              }}
            >
              {Object.entries(row).map(([item, value], cellIndex) => (
                <>
                  {item === "service_name" && (
                    <td
                      key={cellIndex}
                      className="text-secondary !text-[#FFFFFF] py-[24px] px-[16px] min-w-[80px] md:min-w-[120px]"
                    >
                      <TableCell
                        className="!font-medium"
                        item={item}
                        value={value}
                      />
                    </td>
                  )}
                  {item != "submissions" && item !== "service_name" && (
                    <td
                      key={cellIndex}
                      className="text-secondary !font-medium !text-[#FFFFFF] py-[24px] px-[16px] min-w-[80px] md:min-w-[120px]"
                    >
                      <TableCell item={item} value={value} />
                    </td>
                  )}
                </>
              ))}
            </tr>
          ))}
        {!tableData && (
          <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
            {[1, 2, 3, 4, 5].map((el, index) => {
              return (
                <tr key={index}>
                  <td className="px-3 py-4">
                    <Skeleton />
                  </td>
                  <td className="px-3 py-4">
                    <Skeleton />
                  </td>
                  <td className="px-3 py-4">
                    <Skeleton />
                  </td>
                  <td className="px-3 py-4">
                    <Skeleton />
                  </td>
                  <td className="px-3 py-4">
                    <Skeleton />
                  </td>
                  <td className="px-3 py-4">
                    <Skeleton />
                  </td>
                  <td className="px-3 py-4">
                    <Skeleton />
                  </td>
                </tr>
              );
            })}
          </SkeletonTheme>
        )}
      </tbody>
      {setPopup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          submissionsData={submissionsData}
        />
      )}
    </>
  );
}

export default ServiceSubmissionTableBody;

import React from 'react'
import First from './tabs/first';
import Second from './tabs/second';
import Third from './tabs/third';
import Forth from './tabs/forth';
import RequestFirst from '../../service-Opt-in-model/tabs/tabs/first'
import RequestSecond from '../../service-Opt-in-model/tabs/tabs/second'

function Tabs(
  {
    Tab,
    popup,
    formValid,
    nameValid,
    categoryValid,
    websiteUrlValid,
    selectedService,
    setSelectedService,
    setReason,
    setWords,
    Words,
    setReasonType,
    reasonType,
    // submDetailsValid
  }) {

  return (
    <div>
      {Tab === 1 && <First popup={popup} formValid={formValid} nameValid={nameValid} categoryValid={categoryValid} websiteUrlValid={websiteUrlValid} setSelectedService={setSelectedService}/>}
      {Tab === 2 && (selectedService ?  <RequestFirst setReason={setReason} setReasonType={setReasonType} reasonType={reasonType} /> : <Second />)}
      {Tab === 3 && (selectedService ? <RequestSecond setWords={setWords} Words={Words} /> : <Third />)}
      {Tab === 4 && <Forth />}
    </div>
  )
}

export default Tabs
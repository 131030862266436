import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  setEmptyEmployeeData,
  setEmptyEmployees,
} from "../../../redux/slices/personalDashboardSlice";
import { setEmptyEmployeeData as setEmptyCompanyEmployeeData } from "../../../redux/slices/companyDashboardSlice";
import { getColorHex } from "../../../shared/lib/getColorHex";
import { getInitials } from "../../../shared/lib/getInitials";

function EmployeeCell({ data, index }) {
  const [hex, hexAlpha] = getColorHex(index);
  const userInitialsColor = hex;
  const userInitialsBg = hexAlpha;

  return (
    <div
      className="flex pl-[48px] relative flex-start items-center gap-[16px]"
      style={{ cursor: "pointer" }}
    >
      <div
        style={{
          backgroundColor: userInitialsBg,
          color: userInitialsColor,
        }}
        className="absolute top-[50%] font-bold -translate-y-1/2 flex justify-center items-center left-0 w-[32px] h-[32px] rounded-full"
      >
        {getInitials(data.name)}
      </div>
      <div className="flex flex-col">
        <span className="text-secondary !text-left !text-[#FFFFFF] inline_text !font-medium">
          {data.name}
        </span>
        <span className="text-secondary !text-left inline_text">
          {data.work}
        </span>
      </div>
    </div>
  );
}

function TableCell({ item, value, index }) {
  switch (item) {
    case "employee":
      return <EmployeeCell data={value} index={index} />;
    default:
      return value;
  }
}

function TeamTableBody({
  tableData,
  setManagerId,
  handleNameChange,
  setFullName,
  fullName,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleEmptyEmployeeData = (id) => {
    dispatch(setEmptyEmployeeData());
    dispatch(setEmptyCompanyEmployeeData());
    navigate(`/personal_dashboard/teams/${id}/employee_report`);
  };
  const handleEmptyEmployees = () => {
    dispatch(setEmptyEmployees());
  };

  return (
    <tbody>
      {tableData &&
        tableData.map((row, index) => (
          <tr
            key={index}
            className="hover:bg-[#434a5d] cursor-pointer rounded-[24px] text-left"
          >
            {Object.keys(row)
              .filter((item) => item !== "id")
              .map((item, index2, arr) => (
                <td
                  key={index2}
                  className={cn(
                    "text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                  )}
                  onClick={() => {
                    if (index2 === arr.length - 1 && row.also_manager) {
                      // Execute your action when the condition is true
                      setManagerId(row.id);
                      handleNameChange(row.employee.name);
                    } else {
                      // Execute the default action when the condition is false
                      handleEmptyEmployeeData(row.id);
                    }
                  }}
                >
                  {index2 === arr.length - 1 && row.also_manager ? (
                    <Link
                      to={`/personal_dashboard/teams/${row.id}`}
                      className="custom_btn inline_text"
                      onClick={() => {
                        handleEmptyEmployees();
                      }}
                    >
                      See Team Details
                    </Link>
                  ) : (
                    <span>
                      <TableCell item={item} value={row[item]} index={row.id} />
                    </span>
                  )}
                </td>
              ))}
          </tr>
        ))}
      {!tableData && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          {[1, 2, 3].map((el, index) => {
            return (
              <tr key={index}>
                <td className="px-3 py-4 flex items-center gap-4">
                  <Skeleton className="!w-10 !h-10 !rounded-xl" />
                  <Skeleton className="!w-[132px]" />
                </td>
                <td className="px-3 py-4" colSpan="3">
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </SkeletonTheme>
      )}
    </tbody>
  );
}

export default TeamTableBody;

export const heading = ["Category", "Instances", "Cost", ""];

export const tableData = [
  {
    id: 0,
    category: "Flights",
    instances: 3,
    cost: "1250",
  },
  {
    id: 1,
    category: "Hotels",
    instances: 3,
    cost: "1000",
  },
  {
    id: 2,
    category: "Transportation",
    instances: 3,
    cost: "500",
  },
  {
    id: 3,
    category: "Meals",
    instances: null,
    cost: "2300",
  },
  {
    id: 4,
    category: "Office supplies",
    instances: null,
    cost: "1425",
  },
];

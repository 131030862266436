import React from "react";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Pill from "../../Pill/pill";
// import { Link } from "react-router-dom";
function RequestTableBody({
  tableData,
  model = false,
  show,
  setShow,
  setPopup,
  setState,
  setId,
}) {
  return (
    <tbody>
      {tableData &&
        tableData.map((row, index) => (
          <tr
            key={index}
            className="hover:bg-[#363D50] hover:brightness-110 cursor-pointer"
            onClick={() => {
              if (model) {
                setShow(!show);
              } else {
                setPopup(true);
                setState(25);
                setId({ id: row.id, userId: row.userId });
              }
            }}
          >
            {Object.keys(row)
              .filter(
                (item) =>
                  item !== "id" && item !== "userId" && item !== "serviceId"
              )
              .map((item, index2) => (
                <td
                  key={index2}
                  className={cn(
                    "text-secondary !text-[#FFFFFF] py-[19.5px] px-[16px]",
                    {
                      "!text-[16px] !font-medium": item === "date",
                      "!font-medium": item === "comment",
                    }
                  )}
                >
                  {item === "type" && (
                    <Pill className="request-type-case" fill={true}>
                      {row[item]}
                    </Pill>
                  )}
                  {item === "status" && (
                    <span>
                      {row[item] === "open" ? (
                        <Pill
                          className="capitalize !px-1.5 !py-1 !rounded-md !text-xs"
                          greenFill={true}
                        >
                          {row[item]}
                        </Pill>
                      ) : (
                        <Pill
                          className="capitalize !px-1.5 !py-1 !rounded-md !text-xs"
                          redFill={true}
                        >
                          {row[item]}
                        </Pill>
                      )}
                    </span>
                  )}
                  {item === "service" && (
                    <div className="flex flex-col md:flex-row items-center justify-start gap-4">
                      <img
                        className="w-[40px] h-[40px] card_img rounded-lg"
                        src={row[item]["image"]}
                        alt="service-img"
                      />
                      <div className="flex flex-col">
                        <span className="text-secondary !font-medium !text-[#FFFFFF] !text-left">
                          {row[item]["name"]}
                        </span>
                        <span className="text-secondary !text-left min-w-[140px]">
                          {row[item]["category"]}
                        </span>
                      </div>
                    </div>
                  )}
                  {item === "reason" && (
                    <div className="flex flex-col gap-[3px]">
                      <span className="text-secondary !text-[#FFFFFF] !text-left !font-medium">
                        {row[item]["heading"]}
                      </span>
                      <span className="text-secondary !text-left !text-[12px] mt-[-8px]">
                        {row[item]["subHeading"]}
                      </span>
                    </div>
                  )}
                  {item !== "type" &&
                    item !== "status" &&
                    item !== "service" &&
                    item !== "reason" &&
                    row[item]}
                </td>
              ))}
          </tr>
        ))}
      {!tableData && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          {[1, 2, 3, 4, 5].map((el, index) => {
            return (
              <tr key={index}>
                <td className="px-3 py-4 flex items-center gap-4">
                  <Skeleton className="!w-10 !h-10 !rounded-xl" />
                  <Skeleton className="!w-[132px]" />
                </td>
                <td className="px-3 py-4" colSpan="5">
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </SkeletonTheme>
      )}
    </tbody>
  );
}

export default RequestTableBody;

import React from 'react'
import Account from './tabs/Account'
import OrgSetting from './tabs/OrgSetting'
import Integration from './tabs/Integration'
import ManageServices from './tabs/ManageServices'
import ManageEmployees from './tabs/ManageEmployees'


function Tabs({Tab, setModel}) {
  return (
    <div className='h-full'>
      {Tab === 1 && <Account/>}
      {Tab === 2 && <OrgSetting setModel={setModel}/>}
      {/* {Tab === 3 && <Integration/>} */}
      {Tab === 4 && <ManageServices setModel={setModel}/>}
      {Tab === 5 && <ManageEmployees setModel={setModel}/>}
    </div>
  )
}

export default Tabs
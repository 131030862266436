import React from "react";

const Integration = () => {

  return (
    <div className='manage-service-wrapper flex flex-col gap-[24px]'>
      {/*<div className="text-primary !text-[24px] !leading-[24px] text-left">integration data</div>*/}
    </div>
  )
}

export default Integration